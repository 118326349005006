import React, { useState } from 'react';

import Header from '../../components/Header/Header';
import Menu from '../../components/UI/Menu/Menu';
import BookingsRegistryByRooms from '../../components/BookingsRegistryByRooms/BookingsRegistryByRooms';
import BookingsRegistry from '../../components/BookingsRegistry/BookingsRegistry';

import './BookingManagement.css';


function ScreenBookingManagement() {
    const [managementSwitch, setManagementSwitch] = useState(false);

    const onSwitchChange = (bool) => {
        setManagementSwitch(bool);
    }

    const [searchText, setSearchText] = useState("")
    const onInputChange = (text) => {
        setSearchText(text)
    }

    return (
        <div className='booking-management-screen'>
            <Header title='Управление бронями' onSwitchChange={onSwitchChange} onInputChange={onInputChange} withInput />
            <Menu />

            <div className='booking-management-main-wrapper'>
                {managementSwitch ? <BookingsRegistryByRooms searchText={searchText} /> : <BookingsRegistry searchText={searchText} />}
            </div>
        </div>
    );
}


export default ScreenBookingManagement;
