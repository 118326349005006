import React, {memo} from 'react';
import './Button.css';

function Button({text, color, onClick}) {
    return (
        <button
            className={'button ' + color}
            onClick={onClick}>
                {text}
        </button>
    )
}

export default memo(Button)