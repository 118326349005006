import {createAsyncThunk} from "@reduxjs/toolkit";
import ReservationService from "../../services/ReservationService";


export const fetchPaginateBookingsThunk = createAsyncThunk(
    'bookingManagement/fetchAllBookingsThunk',
    async ({pageNum, pageSize, searchText, sorting}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getPaginateBookings(pageNum, pageSize, searchText, sorting?.rawName, sorting?.desc)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchBookingsByRoomsThunk = createAsyncThunk(
    'bookingManagement/fetchBookingsByRoomsThunk',
    async ({date, searchText, officeFilterId}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getBookingsByRooms(date.setUTCHours(0,0,0,0), searchText)
            if (officeFilterId) {
                return data.rooms.filter(room => room.location.id === officeFilterId)
            }
            else return data.rooms

        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchBookingsInOneRoomThunk = createAsyncThunk(
    'bookingManagement/fetchBookingsByOneRoomThunk',
    async ({date, selectedRoomId}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getBookingsInOneRoom(date.setUTCHours(0,0,0,0), selectedRoomId)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fineBookingThunk = createAsyncThunk(
    'bookingManagement/fineBookingThunk',
    async ({selectedBookingId}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.fineBooking(selectedBookingId)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
