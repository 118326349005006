import React, {useEffect, useState} from 'react';
import './Table.css'
import Dropdown from "react-dropdown";
import {useDispatch, useSelector} from "react-redux";
import {setPageNum, setPageSize} from "../../../core/store/slices/paginationSlice";
import {Link} from "react-router-dom";

function Table({ indexId, columns, rows, pageCount, sortable, infiniteUpdate, evenStroke, nowrap, onRowClick, onReturnBookingClick, onCancelBookingClick, onEnterClick, routeTo, onChangeClick, onPaginateUpdate, searchText="" }) {

    const dispatch = useDispatch()
    const { pageNum, pageSize } = useSelector((state) => state.pagination);

    const [sorting, setSorting] = useState({})

    useEffect(() => {
        return () => dispatch(setPageNum(1))
    },[])

    useEffect(() => {
        if (onPaginateUpdate) {
            onPaginateUpdate(pageNum, pageSize, searchText, sorting)
        }
    }, [pageNum, pageSize, searchText, sorting])

    useEffect(() => {
        if (onPaginateUpdate && infiniteUpdate) {
            let interval;
            function startTimer() {
                interval = setInterval(async () => {
                    await onPaginateUpdate(pageNum, pageSize, searchText, sorting)
                }, 5000)
            }
            startTimer()
            return () => clearInterval(interval)
        }
    }, [pageNum, pageSize, searchText, sorting])

    const sizeOnChange = (obj) => {
        dispatch(setPageSize(obj.value))
    }

    const nextPage = () => {
        const nextPage = pageNum + 1
        dispatch(setPageNum(nextPage))
    }

    const prevPage = () => {
        if (pageNum > 1) {
            const prevPage = pageNum - 1
            dispatch(setPageNum(prevPage))
        }
    }

    const firstPage = () => {
        dispatch(setPageNum(1))
    }

    const lastPage = () => {
        dispatch(setPageNum(pageCount))
    }

    const onSortingClick = (columnIndex) => {
        if (sorting.rawName === columns[columnIndex].rawName) {
            setSorting({ rawName: columns[columnIndex].rawName, desc: !sorting.desc })
        }
        else {
            setSorting({ rawName: columns[columnIndex].rawName, desc: false })
        }
    }

    return (
        <div>
            <table className='info-table'>
                <thead>
                    <tr>
                        {columns.map((column, index) =>
                            <th key={index} className={sortable && sorting?.rawName === column.rawName ? sorting.desc ? 'sorting-desc' : 'sorting-asc' : undefined} onClick={sortable ? () => onSortingClick(index) : undefined}>
                                {sortable ? column.name : column}
                            </th>
                        )}
                        {onEnterClick && <th></th>}
                        {onChangeClick && <th></th>}
                        {(onCancelBookingClick || onReturnBookingClick) && <th></th>}
                    </tr>
                </thead>

                <tbody>
                {rows.map((row, index) =>
                    <tr
                        onClick={onRowClick ? () => onRowClick(row[indexId]) : undefined}
                        className={`${onRowClick && 'table-row-click'} ${evenStroke && 'even-stroke'}`}
                        key={index}
                    >
                        {columns.map((column, val_index) =>
                            <td className={nowrap && 'table-booking-row-nowrap'} key={val_index}>{row[val_index]}</td>
                        )}
                        {onEnterClick && <td><Link onClick={() => onEnterClick(row[indexId])} className='table-booking-delete' to={`${routeTo}/${row[indexId]}`}>перейти к брони</Link></td>}
                        {row[row.length - 2]==='changeButton' && <td onClick={() => onChangeClick(row[indexId])} className='table-booking-delete'>изменить</td>}
                        {row[row.length - 1]==='cancelButton' && <td onClick={() => onCancelBookingClick(row[indexId])} className='table-booking-delete'>снять бронь</td>}
                        {row[row.length - 1]==='returnButton' && <td onClick={() => onReturnBookingClick(row[indexId])} className='table-booking-delete'>вернуть бронь</td>}
                        {onChangeClick && !['cancelButton', 'returnButton'].includes(row[row.length - 1]) && !['changeButton'].includes(row[row.length - 2]) && <td></td>}
                        {(onCancelBookingClick || onReturnBookingClick) && !['cancelButton', 'returnButton'].includes(row[row.length - 1]) && <td></td>}
                    </tr>
                )}
                </tbody>

            </table>
            {onPaginateUpdate &&
                <div className='table-pagination'>
                    <Dropdown onChange={sizeOnChange} value={pageSize.toString()} options={[20, 50, 100]}/>

                    <div className='table-pagination-pages'>
                        {pageNum!==1 && pageNum!==2 && <div onClick={firstPage}>«</div>}
                        {pageNum!==1 && <div onClick={prevPage}>‹</div>}
                        {pageNum} {pageNum !== pageCount && <div className='pagination-page-count' onClick={lastPage}>... {pageCount}</div>}
                        {pageNum !== pageCount && <div onClick={nextPage}>›</div>}
                        {pageNum !== pageCount && pageNum !== pageCount-1 && <div onClick={lastPage}>»</div>}
                    </div>
                </div>
            }
        </div>
    );
}

export default Table;