
export const getEnrichedContents = (scrollContainer) => {
    return Array.from(scrollContainer.children)
};
export const getContents = (scrollContainer) => {
    const contentsUnflattened = Array.from(scrollContainer.children).map(
        (child, index) => Array.from(child.children)
    );
    return contentsUnflattened.reduce((prev, curr) => {
        return prev.concat(curr);
    }, []);
};

/* Get the center area of the container (size is equal to a content element) */
export const getContainerCenter = (container, verticalScroll) => {
    const upper = verticalScroll ? container.getBoundingClientRect().top : container.getBoundingClientRect().left;
    const lower = verticalScroll ? container.getBoundingClientRect().bottom : container.getBoundingClientRect().right;
    return (upper + lower) / 2;
};

/* Get the intersection between two intervals */
export const getIntersection = (
    interval1,
    interval2
) => {
    const [l1, h1] = interval1;
    const [l2, h2] = interval2;

    if (h2 >= l1 && l2 <= h1) {
        return [Math.max(l1, l2), Math.min(h1, h2)];
    }

    return null;
};

export function formatTime(time) {
    return `${('0' + time.getHours()).slice(-2)}:${('0' + time.getMinutes()).slice(-2)}`
}

export function formatToISODateString(date) {
    return date.toISOString().split('T')[0]
}

export function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export function convertDateToMoscowTimeZone(date) {
    if (typeof date === 'string') {
        return new Date(
            new Date(date).toLocaleString('en-US', {
                timeZone: 'Europe/Moscow'
            }),
        );
    }

    return new Date(
        date.toLocaleString('en-US', {
            timeZone: 'Europe/Moscow'
        }),
    );
}

export function getCurrentMoscowTime() {
    return new Date(
        new Date().toLocaleString('en-US', {
            timeZone: 'Europe/Moscow'
        }),
    );
}
