import React from 'react';
import {Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PrivateRoutes } from '../../../core/utils/constants';
import { Roles } from '../../../core/utils/constants';
import { removeAllBreadCrumbs } from '../../../core/store/slices/navigationSlice';

import './Menu.css';


function Menu() {
    const { user } = useSelector((state) => state.auth)

    const dispatch = useDispatch();

    const onMenuElementClick = () => {
        dispatch(removeAllBreadCrumbs());
    }

    return [Roles.USER, Roles.ADMIN, Roles.SUPERADMIN].includes(user.role) && (
        <div className='menu'>
            <div className='menu-icon-panel'>
                <Link className='menu-icon m-booking-search' to={PrivateRoutes.Reservation} onClick={onMenuElementClick}>
                    <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M25.093 28.9544C25.093 28.8424 25.163 26.2104 23.5041 24.6915C23.3431 24.5445 23.0911 24.5305 22.9161 24.6635C22.8461 24.7195 21.0961 26.0144 19.4512 24.6635C19.2762 24.5235 19.0312 24.5304 18.8632 24.6704C18.8422 24.6914 18.6252 24.8804 18.3592 25.2374C18.1072 24.8454 17.7993 24.4745 17.4353 24.1385C17.2743 23.9915 17.0223 23.9775 16.8473 24.1105C16.7353 24.1945 14.1244 26.1334 11.6464 24.1105C11.4714 23.9705 11.2265 23.9775 11.0585 24.1175C10.9675 24.2015 8.74155 26.1754 8.68555 30.0603C8.68555 30.1793 8.73453 30.2983 8.81852 30.3893C8.90252 30.4733 9.02153 30.5223 9.14053 30.5223H19.1852C19.4302 30.5223 19.6332 30.3263 19.6402 30.0813C19.6402 30.0393 19.6472 29.7803 19.6192 29.3813H24.6311C24.8761 29.3813 25.079 29.1853 25.086 28.9403L25.093 28.9544ZM9.62352 29.6263C9.78451 27.1904 10.8975 25.6644 11.4085 25.0835C13.7814 26.7354 16.2033 25.5874 17.0713 25.0624C18.4152 26.5114 18.6742 28.6953 18.7232 29.6193H9.61651L9.62352 29.6263ZM19.5282 28.4853C19.4232 27.7994 19.2202 26.9664 18.8492 26.1474C18.9822 25.9304 19.1082 25.7555 19.2202 25.6295C20.6902 26.5814 22.2511 26.1404 23.1331 25.6224C23.9171 26.5324 24.1131 27.8204 24.1621 28.4853H19.5282Z'
                            fill='white' />
                        <path
                            d='M20.3686 12.8128H16.8057V13.9328C16.8057 14.4927 17.2607 14.9477 17.8206 14.9477H20.9845L21.8875 15.9837C21.9505 16.0607 22.0485 16.1027 22.1465 16.1027C22.1885 16.1027 22.2375 16.0957 22.2795 16.0747C22.4195 16.0187 22.5035 15.8787 22.4895 15.7317L22.4265 14.9477H24.0504C24.6104 14.9477 25.0654 14.4927 25.0654 13.9328V9.9219C25.0654 9.36192 24.6104 8.90692 24.0504 8.90692H21.8315V11.3428C21.8315 12.1548 21.1735 12.8128 20.3616 12.8128H20.3686ZM21.9225 11.3639V9.58588H24.0575C24.2394 9.58588 24.3864 9.7329 24.3864 9.91489V13.9258C24.3864 14.1078 24.2394 14.2548 24.0575 14.2548H22.0625C21.9645 14.2548 21.8735 14.2967 21.8105 14.3667C21.7475 14.4367 21.7125 14.5277 21.7195 14.6257V14.7447L21.4045 14.3738C21.3415 14.2968 21.2435 14.2548 21.1455 14.2548H17.8206C17.6386 14.2548 17.4917 14.1078 17.4917 13.9258V12.8548H20.4176C21.2435 12.8548 21.9225 12.1828 21.9225 11.3498V11.3639Z'
                            fill='white' />
                        <path
                            d='M8.00701 11.3428C8.00701 12.1547 8.66497 12.8127 9.47695 12.8127H13.4248L13.3128 14.2267C13.2988 14.3947 13.3968 14.5557 13.5508 14.6187C13.5998 14.6397 13.6488 14.6467 13.7048 14.6467C13.8168 14.6467 13.9288 14.5977 13.9988 14.5137L15.4828 12.8057H20.3616C21.1736 12.8057 21.8316 12.1477 21.8316 11.3358V5.46994C21.8316 4.65796 21.1736 4 20.3616 4H9.46996C8.65798 4 8 4.65796 8 5.46994V11.3358L8.00701 11.3428ZM8.79796 5.47694C8.79796 5.09896 9.10596 4.79799 9.47695 4.79799H20.3686C20.7466 4.79799 21.0476 5.10596 21.0476 5.47694V11.3428C21.0476 11.7208 20.7396 12.0218 20.3686 12.0218H15.3148C15.2028 12.0218 15.0908 12.0708 15.0138 12.1548L14.1948 13.0927L14.2438 12.4417C14.2508 12.3297 14.2158 12.2248 14.1388 12.1408C14.0618 12.0568 13.9568 12.0148 13.8518 12.0148H9.47695C9.09896 12.0148 8.79796 11.7068 8.79796 11.3358V5.46994V5.47694Z'
                            fill='white' />
                        <path
                            d='M21.1316 24.6635C21.9716 24.6635 22.7556 24.3345 23.3506 23.7395C23.9455 23.1445 24.2745 22.3535 24.2745 21.5205C24.2745 19.7916 22.8676 18.3776 21.1316 18.3776C20.2917 18.3776 19.5077 18.7066 18.9127 19.3016C18.7307 19.4836 18.5837 19.6796 18.4507 19.8896C18.4647 19.7496 18.4717 19.6026 18.4717 19.4626C18.4717 17.0966 16.5398 15.1647 14.1739 15.1647C13.0259 15.1647 11.9479 15.6127 11.136 16.4247C10.324 17.2367 9.87598 18.3146 9.87598 19.4626C9.87598 21.8285 11.8079 23.7605 14.1739 23.7605C15.3218 23.7605 16.3998 23.3125 17.2118 22.5005C17.5338 22.1785 17.7927 21.8145 17.9957 21.4225C17.9957 21.4575 17.9957 21.4855 17.9957 21.5205C17.9957 23.2495 19.4027 24.6635 21.1386 24.6635H21.1316ZM19.5567 19.9456C19.9767 19.5256 20.5367 19.2946 21.1316 19.2946C22.3566 19.2946 23.3576 20.2955 23.3576 21.5205C23.3576 22.1155 23.1266 22.6755 22.7066 23.0955C22.2866 23.5155 21.7266 23.7465 21.1316 23.7465C19.9067 23.7465 18.9057 22.7455 18.9057 21.5205C18.9057 20.9255 19.1367 20.3655 19.5567 19.9456ZM16.5678 21.8565C15.9308 22.4935 15.0768 22.8505 14.1739 22.8505C12.3049 22.8505 10.793 21.3315 10.786 19.4696C10.786 18.5666 11.1359 17.7196 11.7799 17.0757C12.4169 16.4317 13.2709 16.0817 14.1739 16.0817C16.0358 16.0817 17.5548 17.6006 17.5618 19.4626C17.5618 20.3655 17.2118 21.2125 16.5678 21.8565Z'
                            fill='white' />
                    </svg>

                    <span>Поиск переговорок</span>
                </Link>
                {user.role!==Roles.USER &&
                    <Link className='menu-icon m-booking' to={PrivateRoutes.BookingManagement}
                           onClick={onMenuElementClick}>
                        <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M18.4116 9H4.41387C4.19215 9 4 9.20694 4 9.45082V26.523C4 26.7669 4.19215 26.9738 4.41387 26.9738H14.7533V29.5753C14.7533 29.8857 15.1154 30.174 15.374 29.967C15.6623 29.7305 15.9579 29.494 16.2461 29.2575C16.4605 29.0802 16.6748 28.9102 16.8891 28.7328C16.9409 28.6885 17.0222 28.6367 17.0961 28.5924C17.1404 28.6219 17.1774 28.6515 17.2069 28.6811C17.3917 28.8658 17.6208 29.021 17.8277 29.191C18.1455 29.4497 18.4633 29.7084 18.7811 29.9744C18.9585 30.1222 19.1359 30.0705 19.2467 29.9301C19.3354 29.8562 19.4019 29.7453 19.4019 29.5827V26.9812H29.5935C29.7118 26.9812 29.8078 26.9295 29.8743 26.863L29.8891 26.8482C29.9556 26.7669 30 26.6635 30 26.5304V9.45821C30 9.21432 29.8078 9.00739 29.5861 9.00739H18.4042L18.4116 9ZM14.7533 26.0722H4.82774V9.90165H14.7533V26.0722ZM18.5742 28.6737C18.1455 28.3263 17.7169 27.9716 17.2882 27.6242C17.1848 27.5355 16.9778 27.5355 16.8743 27.6242C16.4457 27.979 16.0097 28.3263 15.581 28.6811V9.90165H18.5816V28.6737H18.5742ZM19.4019 9.90165H29.1796V26.0722H19.4019V9.90165Z'
                                fill='white'/>
                            <path
                                d='M6.6977 14.1439H12.5289C12.8836 14.1439 12.8836 13.5378 12.5289 13.5378H6.6977C6.34295 13.5378 6.34295 14.1439 6.6977 14.1439Z'
                                fill='white'/>
                            <path
                                d='M6.6977 16.6788H12.5289C12.8836 16.6788 12.8836 16.0728 12.5289 16.0728H6.6977C6.34295 16.0728 6.34295 16.6788 6.6977 16.6788Z'
                                fill='white'/>
                            <path
                                d='M6.6977 19.2137H12.5289C12.8836 19.2137 12.8836 18.6077 12.5289 18.6077H6.6977C6.34295 18.6077 6.34295 19.2137 6.6977 19.2137Z'
                                fill='white'/>
                            <path
                                d='M6.6977 21.7487H12.5289C12.8836 21.7487 12.8836 21.1427 12.5289 21.1427H6.6977C6.34295 21.1427 6.34295 21.7487 6.6977 21.7487Z'
                                fill='white'/>
                            <path
                                d='M27.3316 13.5453H21.5004C21.1457 13.5453 21.1457 14.1513 21.5004 14.1513H27.3316C27.6863 14.1513 27.6863 13.5453 27.3316 13.5453Z'
                                fill='white'/>
                            <path
                                d='M27.3316 16.0728H21.5004C21.1457 16.0728 21.1457 16.6788 21.5004 16.6788H27.3316C27.6863 16.6788 27.6863 16.0728 27.3316 16.0728Z'
                                fill='white'/>
                            <path
                                d='M27.3316 18.6078H21.5004C21.1457 18.6078 21.1457 19.2138 21.5004 19.2138H27.3316C27.6863 19.2138 27.6863 18.6078 27.3316 18.6078Z'
                                fill='white'/>
                            <path
                                d='M27.3316 21.1427H21.5004C21.1457 21.1427 21.1457 21.7487 21.5004 21.7487H27.3316C27.6863 21.7487 27.6863 21.1427 27.3316 21.1427Z'
                                fill='white'/>
                        </svg>

                        <span>Управление бронями</span>
                    </Link>
                }
                {user.role !== Roles.USER &&
                    <Link className='menu-icon m-rooms' to={PrivateRoutes.MeetingRoomsManagement}
                          onClick={onMenuElementClick}>
                        <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M29.9374 17.5836H20.4659V5H6.84977V30.57H0V31.5222H35V30.57H29.9374V17.5763V17.5836ZM19.5549 30.1672H7.75384V5.95951H19.5549V30.1672ZM29.0264 30.1672H20.605V18.5431H29.0264V30.1672Z'
                                fill='white'/>
                            <path
                                d='M13.0246 8.61816H10.1108V11.255H13.0246V8.61816ZM12.5726 10.7716H10.5629V9.09428H12.5726V10.7716Z'
                                fill='white'/>
                            <path
                                d='M17.2116 8.61816H14.2979V11.255H17.2116V8.61816ZM16.7596 10.7716H14.7499V9.09428H16.7596V10.7716Z'
                                fill='white'/>
                            <path
                                d='M17.2116 12.0463H14.2979V14.6758H17.2116V12.0463ZM16.7596 14.1997H14.7499V12.5224H16.7596V14.1997Z'
                                fill='white'/>
                            <path
                                d='M17.2116 18.1036H14.2979V20.7405H17.2116V18.1036ZM16.7596 20.2571H14.7499V18.5798H16.7596V20.2571Z'
                                fill='white'/>
                            <path
                                d='M17.2116 24.1099H14.2979V26.7394H17.2116V24.1099ZM16.7596 26.2633H14.7499V24.5859H16.7596V26.2633Z'
                                fill='white'/>
                            <path
                                d='M13.0246 24.1099H10.1108V26.7394H13.0246V24.1099ZM12.5726 26.2633H10.5629V24.5859H12.5726V26.2633Z'
                                fill='white'/>
                            <path
                                d='M24.6457 19.5762H21.7319V22.213H24.6457V19.5762ZM24.1937 21.7296H22.184V20.0523H24.1937V21.7296Z'
                                fill='white'/>
                            <path
                                d='M28.3034 23.8314H25.3896V26.461H28.3034V23.8314ZM27.8514 25.9848H25.8416V24.3075H27.8514V25.9848Z'
                                fill='white'/>
                        </svg>

                        <span>Переговорки</span>
                    </Link>
                }
                <Link className='menu-icon m-party' to={PrivateRoutes.PartyManagement} onClick={onMenuElementClick}>
                    <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M12.6672 26.0698C12.8038 26.2596 13.0391 26.3507 13.2745 26.3128L17.9052 25.4777L21.2151 28.8179C21.3365 28.9394 21.4884 29.0001 21.6554 29.0001C21.7237 29.0001 21.7844 28.9925 21.8528 28.9697C22.0729 28.8938 22.2399 28.7041 22.2703 28.4687L22.908 23.8076L27.106 21.6896C27.3186 21.5833 27.4476 21.3707 27.4476 21.1354C27.4476 20.9001 27.311 20.6875 27.0984 20.5813L22.8624 18.5392L22.1488 13.8932C22.1109 13.6579 21.9514 13.4681 21.7237 13.3998C21.496 13.3315 21.253 13.3922 21.0936 13.5592L17.8369 16.9526L13.1986 16.1935C12.9632 16.1555 12.7355 16.2542 12.5988 16.4439C12.4622 16.6337 12.447 16.8919 12.5609 17.0968L14.7852 21.2417L12.6292 25.4246C12.5229 25.6371 12.5381 25.8876 12.6823 26.0774L12.6672 26.0698ZM16.015 21.515C16.1061 21.3328 16.1061 21.1203 16.015 20.9381L14.231 17.613L17.9584 18.2203C18.1557 18.2507 18.3607 18.1824 18.5049 18.0382L21.1164 15.3128L21.6933 19.0402C21.7237 19.2452 21.8528 19.4122 22.0349 19.5033L25.4359 21.143L22.0653 22.8435C21.8831 22.9346 21.7617 23.1092 21.7313 23.3142L21.2227 27.0567L18.5657 24.377C18.4518 24.2555 18.2924 24.1948 18.1254 24.1948C18.0874 24.1948 18.0495 24.1948 18.0191 24.2024L14.3069 24.8704L16.0377 21.515H16.015Z'
                            fill='white' />
                        <path
                            d='M29.52 10.6135L28.3965 11.9572L26.6505 11.7978C26.4152 11.775 26.1874 11.8889 26.066 12.0863C25.9445 12.2836 25.9445 12.5417 26.066 12.7391L26.9997 14.2194L26.3089 15.8288C26.2178 16.0414 26.2557 16.2919 26.4076 16.4741C26.5594 16.6563 26.8023 16.7322 27.0301 16.6715L28.7305 16.2464L30.0438 17.4002C30.1577 17.4989 30.3019 17.5521 30.4538 17.5521C30.5297 17.5521 30.6132 17.5369 30.6891 17.5065C30.9093 17.4154 31.0535 17.2105 31.0687 16.9751L31.1901 15.2291L32.7008 14.3333C32.9058 14.2119 33.0197 13.9917 33.0045 13.7564C32.9893 13.521 32.8375 13.3161 32.6173 13.2326L30.9928 12.5797L30.6056 10.8717C30.5525 10.6439 30.3779 10.4617 30.1501 10.4086C29.9224 10.3554 29.6795 10.4313 29.5276 10.6135H29.52ZM29.9755 14.8267L29.9224 15.6466L29.2999 15.1001C29.186 15.0014 29.0418 14.9482 28.89 14.9482C28.8368 14.9482 28.7913 14.9482 28.7381 14.971L27.941 15.1684L28.2675 14.4092C28.351 14.2194 28.3282 14.0069 28.2219 13.8323L27.7816 13.1339L28.6015 13.2098C28.8064 13.225 29.0038 13.1491 29.1329 12.9896L29.6643 12.3595L29.8465 13.1642C29.892 13.3616 30.0287 13.5286 30.2184 13.6045L30.9852 13.9082L30.2792 14.3257C30.1046 14.432 29.9907 14.6142 29.9755 14.8192V14.8267Z'
                            fill='white' />
                        <path
                            d='M4.27865 13.5973L3.64856 16.5883C3.60301 16.816 3.68651 17.0589 3.86871 17.2032C4.0509 17.3474 4.30901 17.3778 4.52157 17.2715L7.27725 15.9506L9.92665 17.4765C10.0253 17.5296 10.1316 17.56 10.2379 17.56C10.3593 17.56 10.4808 17.522 10.5795 17.4537C10.7769 17.3247 10.8756 17.0893 10.8452 16.8616L10.4429 13.8326L12.7127 11.783C12.8873 11.6235 12.9556 11.3806 12.8949 11.1529C12.8341 10.9251 12.6444 10.7581 12.409 10.7125L9.40284 10.1584L8.15785 7.36475C8.05916 7.1522 7.85419 7.00793 7.61886 7.00034C7.38352 6.99274 7.16337 7.11425 7.0495 7.32681L5.59196 10.0141L2.5554 10.3406C2.32006 10.3633 2.12269 10.5228 2.03919 10.7429C1.95568 10.9631 2.00882 11.2136 2.16824 11.3806L4.27865 13.5973V13.5973ZM6.52569 10.8871L7.53535 9.01207L8.40837 10.9555C8.49187 11.1453 8.65888 11.2743 8.86385 11.3123L10.9591 11.6994L9.38007 13.1266C9.22824 13.2632 9.15232 13.4682 9.18268 13.6656L9.46357 15.776L7.61886 14.7132C7.52017 14.6601 7.41389 14.6297 7.30761 14.6297C7.21651 14.6297 7.12542 14.6525 7.04192 14.6904L5.12129 15.609L5.56159 13.529C5.60714 13.3316 5.54641 13.119 5.40217 12.9748L3.93704 11.4337L6.05503 11.206C6.26 11.1832 6.4346 11.0617 6.53329 10.8871H6.52569Z'
                            fill='white' />
                    </svg>

                    <span>Мероприятия</span>
                </Link>
            </div>
        </div>
    );
}


export default Menu;
