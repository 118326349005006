import React from 'react';
import './FoundRooms.css'
import {useDispatch, useSelector} from "react-redux";
import {setDate, setFinishTime, setSelectedRoomId, setStartTime} from "../../core/store/slices/reservationSlice";
import {formatTime} from "../../core/utils/functions";
import BookingList from "../BookingList/BookingList";
import ColorRingLoader from "../UI/ColorRingLoader/ColorRingLoader";
import {findRoomsThunk} from "../../core/store/actions/reservationActions";
import {useNavigate} from "react-router-dom";
import {PrivateRoutes} from "../../core/utils/constants";


function FoundRooms({onItemClick}) {
    const {loading, selectedOfficeId, numberPeople, foundRooms, date, startTime, finishTime} = useSelector((state) => state.reservation)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const nextDate = () => {
        const newDate = new Date(date.getTime() + (24 * 60 * 60 * 1000))
        dispatch(setDate(newDate))
        dispatch(findRoomsThunk({selectedOfficeId, date: newDate, startTime, finishTime, numberPeople}))
    }

    const onTimeButtonClick = (startTime, finishTime, roomId) => {
        dispatch(setStartTime(new Date(startTime)))
        dispatch(setFinishTime(new Date(finishTime)))
        dispatch(setSelectedRoomId(roomId))
        navigate(PrivateRoutes.RoomDescription)
    }

    return (
        <div className='found-rooms-content'>
            <div
                className='found-rooms-list'
            >
                <div className='found-rooms-relevant'>
                    <div className='found-rooms-title'>
                        Подходящие результаты
                        <div className='found-rooms-title-time'>НА {formatTime(startTime)}-{formatTime(finishTime)}</div>
                        <div className='found-rooms-title-date'>
                            {date.toLocaleDateString()+' '}
                            <svg onClick={nextDate} width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.1543 6.5L0.538913 12.1292L0.538913 0.870835L8.1543 6.5Z" fill="black"/>
                            </svg>
                        </div>
                    </div>
                    {
                        foundRooms.relevant.length ?
                            <div className='found-rooms-scroller'><BookingList rooms={foundRooms.relevant} onItemClick={onItemClick}/></div> : <div className='found-rooms-subtitle'>Подходящих вариантов нет</div>
                    }
                </div>

                <div className='found-rooms-alternative'>
                    {loading && <ColorRingLoader />}
                    {(foundRooms.alternative_size.length > 0 || foundRooms.alternative_time.length > 0) && <div className='found-rooms-title'>Альтернативы</div>}
                    {
                        foundRooms.alternative_size.length > 0 &&
                            <div>
                                <div className='found-rooms-subtitle'>Подходящее время</div>
                                <BookingList rooms={foundRooms.alternative_size} onItemClick={onItemClick}/>
                            </div>
                    }
                    {
                        foundRooms.alternative_time.length > 0 &&
                            <div>
                                <div className='found-rooms-subtitle'>Подходящий размер</div>
                                <BookingList rooms={foundRooms.alternative_time} withTimesButtons={true} onTimeButtonClick={onTimeButtonClick} />
                            </div>
                    }
                    {
                        foundRooms.alternative_size.length===0 && foundRooms.alternative_time.length===0 && foundRooms.relevant.length===0 &&
                            <div>
                                <div className='found-rooms-subtitle'>Альтернатив нет</div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FoundRooms;
