import React, {useState} from 'react';
import './Header.css';
import {useDispatch} from "react-redux";
import {setOpenedModal} from "../../core/store/slices/modalsSlice";
import {useDebounce} from "../../core/hooks/useDebounce";
import Switch from "rc-switch";
import {signOutThunk} from "../../core/store/actions/authActions";
import {deleteAllCookies} from "../../core/utils/functions";
import {Link, useNavigate} from "react-router-dom";
import {PrivateRoutes, PublicRoutes} from "../../core/utils/constants";
import Button from "../UI/Button/Button";

function Header({title, selectedOffice, modalName, onSwitchChange, withInput, onInputChange, onAddClick}) {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const toggleModal = (modal) => {
        dispatch(setOpenedModal(modal))
    }

    const onInputKeyDown = useDebounce((e) => {
        if (typeof onInputChange === 'function') {
            onInputChange(e.target.value)
        }
    }, 300)

    const onSignOutClick = () => {
        dispatch(signOutThunk({}))
        deleteAllCookies()
        localStorage.clear()
        sessionStorage.clear()
        navigate(PublicRoutes.SignIn)
    }

    return (
        <div className="header">
            <section className="header-left-wrapper">
                <Link className="header-logo" to={PrivateRoutes.Reservation}>
                    <img src="/img/icons/logo_TI.svg" alt="Team IT" />
                </Link>
                <div className="header-name">
                    {title}
                    {onSwitchChange && <Switch onChange={onSwitchChange} />}
                </div>
            </section>
            <section className="header-right-wrapper">
                {selectedOffice &&
                    <div className="header-loc" onClick={() => toggleModal(modalName)}>
                        {selectedOffice}
                        <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 6L0.870834 0L12.1292 0L6.5 6Z" fill="black"/>
                        </svg>
                    </div>
                }
                {withInput &&
                    <input
                        className="header-search"
                        placeholder="Поиск"
                        onKeyDown={onInputKeyDown}
                    />
                }
            </section>
            <img className='sign-out-btn' src='/img/icons/exit.svg' alt='Exit' width='30px' onClick={onSignOutClick}/>
            {
                onAddClick &&
                    <div className='add-button-wrapper'>
                        <Button text='Добавить' color='black' onClick={onAddClick}/>
                    </div>
            }
        </div>
    )
}

export default Header
