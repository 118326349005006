import axios from 'axios';
import AuthService from "../services/AuthService";
import {
    getAccessTokenOrNullFromStorage,
    getRefreshTokenOrNullFromStorage, putAccessTokenToStorage,
    putTokensToStorage
} from "../store/utils/functions";

export const API_URL = process.env.REACT_APP_API_URL

let refreshTokenRequest = null;

export const api = axios.create({
    withCredentials: true,
    baseURL: API_URL
})

api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
    return config;
})

api.interceptors.response.use((config) => {
    return config;
},async (error) => {
    const originalRequest = error?.config;
    if (error?.response?.status === 401 && error?.config && !error?.config?._isRetry) {
        originalRequest._isRetry = true;
        try {
            const accessToken = getAccessTokenOrNullFromStorage()
            const refreshToken = getRefreshTokenOrNullFromStorage()
            let response
            if (accessToken && refreshToken) {
                if (refreshTokenRequest===null) {
                    refreshTokenRequest = AuthService.refreshWithHeader(refreshToken)
                }
                response = await refreshTokenRequest
                putTokensToStorage(response.data.access_token, response.data.refresh_token)
            }
            else if (accessToken) {
                if (refreshTokenRequest===null) {
                    refreshTokenRequest = AuthService.refreshWithCookie()
                }
                response = await refreshTokenRequest
                putAccessTokenToStorage(response.data.access_token)
            }
            refreshTokenRequest = null

            originalRequest.headers = { ...originalRequest.headers }
            originalRequest.headers['Authorization'] = `Bearer ${response.data.access_token}`

            return api.request(originalRequest);
        } catch (e) {
            alert('Ошибка авторизации')
        }
    }
    throw error;
})