import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import TelegramLoginButton from 'react-telegram-login';

import { browserTgWidgetSignInThunk } from '../../core/store/actions/authActions';
import { PrivateRoutes } from '../../core/utils/constants';

import './Login.css';


const ScreenLogin = () => {
    const { user, error } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate(PrivateRoutes.Reservation);
        }
    }, [ navigate, user ]);

    async function onTelegramAuth(auth_data) {
        dispatch(browserTgWidgetSignInThunk({auth_data}));

        if (user) {
            navigate(PrivateRoutes.Reservation);
        }
    }

    return (
        <div className='login-container'>
            <div className='login-composite-icon'>
                <img className='login-panda-icon' src='/img/icons/Panda.svg' alt='Panda' />
                <img className='login-team-it-icon' src='/img/icons/TeamIT.svg' alt='Team IT' />
            </div>

            <div className='login-panel'>
                <TelegramLoginButton dataOnauth={onTelegramAuth} botName={process.env.REACT_APP_BOT_NAME} cornerRadius='6' lang='ru' />
                <span className='login-panel-span'>По корпоративному номеру</span>
            </div>

            {error && <div>{error}</div>}
        </div>
    );
}


export default ScreenLogin;
