import React, {useEffect, useState} from 'react';
import './Schedule.css'
import DefaultModal from "../DefaultModal/DefaultModal";
import Table from "../../UI/Table/Table";
import {
    setPMConflicts,
    setPMEditableEventDate,
    setPMEditableEventFinishTime,
    setPMEditableEventStartTime,
    setPMError, setPMSelectedEventDescription,
    setPMSelectedEventId,
    setPMStatus
} from "../../../core/store/slices/partiesManagementSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Button from "../../UI/Button/Button";
import FilterButton from "../../UI/FilterButton/FilterButton";
import {bookingStatus, Modals, PrivateRoutes, Roles} from "../../../core/utils/constants";
import {formatTime} from "../../../core/utils/functions";
import DayPicker from "../DayPicker/DayPicker";
import DropDownTimePicker from "../DropDownTimePicker/DropDownTimePicker";
import {setOpenedModal} from "../../../core/store/slices/modalsSlice";
import {
    fetchEventConflictsThunk,
    fetchPaginateEventsThunk, fetchSelectedPartyThunk, resolveEventConflictsThunk,
} from "../../../core/store/actions/partiesManagementActions";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import {cancelBooking, editBookingThunk} from "../../../core/store/actions/reservationActions";
import {setError, setStatus} from "../../../core/store/slices/reservationSlice";

function Schedule({onExitClick, adding}) {
    const columns = ['№', 'Переговорка', 'Дата брони', 'Время брони', 'Конец брони', 'Статус', 'Конфликт']
    const indexOfEventId = columns.indexOf('№')

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.auth)
    const { status, error: secError } = useSelector((state) => state.reservation)
    const { status: statusPM, error, conflicts, date, loading, selectedEventDescription, editableEventDate, editableEventStartTime, editableEventFinishTime, selectedPartyId, selectedPartyDescription, selectedEventId } = useSelector((state) => state.partiesManagement);
    const { openedModal } = useSelector((state) => state.modals)
    const { pageNum, pageSize } = useSelector((state) => state.pagination);

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [notification, setNotification] = useState(true);
    const [changingModal, setChangingModal] = useState(false);
    const [bookingIdToDelete, setBookingIdToDelete] = useState(null);

    const fetchEventsByPartyId = () => {
        dispatch(fetchEventConflictsThunk({selectedPartyId}))
        dispatch(fetchSelectedPartyThunk({selectedPartyId, withBookings: true}))
    }

    useEffect(() => {
        fetchEventsByPartyId()

        return () => {
            dispatch(setPMConflicts(null))
        }
    },[])

    function getEventsRows() {
        let rows = []
        if (conflicts?.length > 0) {
            conflicts.map(element => {
                rows.push([
                    element.event_booking.id,
                    element.event_booking.room_name,
                    new Date(element.event_booking.date * 1000).toLocaleDateString(),
                    new Date(element.event_booking.start_time * 1000).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    new Date(element.event_booking.finish_time * 1000).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    element.event_booking.status===bookingStatus.ongoing ? <div>{element.event_booking.status}<span className='blink'> ◉</span></div> : element.event_booking.status,
                    element.event_booking.tenant + ": " + element.event_booking.description,
                    'changeButton',
                    'cancelButton'
                ])
                rows.push([
                    element.other_booking.id,
                    element.other_booking.room_name,
                    new Date(element.other_booking.date * 1000).toLocaleDateString(),
                    new Date(element.other_booking.start_time * 1000).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    new Date(element.other_booking.finish_time * 1000).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    element.other_booking.status===bookingStatus.ongoing ? <div>{element.other_booking.status}<span className='blink'> ◉</span></div> : element.other_booking.status,
                    element.other_booking.tenant + ": " + element.other_booking.description,
                    user.role!==Roles.USER && 'changeButton',
                    user.role!==Roles.USER && 'cancelButton'
                ])
            })
        }
        else {
            selectedPartyDescription?.bookings?.map(element => {
                rows.push([
                    element.id,
                    element.room_name,
                    new Date(element.date * 1000).toLocaleDateString(),
                    new Date(element.start_time * 1000).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    new Date(element.finish_time * 1000).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    element.status===bookingStatus.ongoing ? <div>{element.status}<span className='blink'> ◉</span></div> : element.status,
                    '-',
                    element.status===bookingStatus.booked && 'changeButton',
                    element.status===bookingStatus.booked && 'cancelButton',
                ])
            })
        }

        return rows;
    }


    const onCancelBookingClick = (bookingIdToDelete) => {
        setDeleteConfirmationModal(true);
        setBookingIdToDelete(bookingIdToDelete);
    }

    const onConflictCancellingEventClick = async () => {
        setDeleteConfirmationModal(false);
        // dispatch(cancelConflictEvent({  }));
    }
    
    const onCurrentCancellingEventClick = () => {
        setDeleteConfirmationModal(false);
        dispatch(cancelBooking({ selectedBookingId: bookingIdToDelete }));
    }

    const onRejectCancellingEventClick = () => {
        setBookingIdToDelete(null);
        setDeleteConfirmationModal(false);
    }

    const onModalCompleteExit = () => {
        dispatch(setStatus(null))
        dispatch(setPMStatus(null))
        fetchEventsByPartyId()
    }

    const onChangingClick = (eventId) => {
        dispatch(setPMSelectedEventId(eventId))
        let event
        if (conflicts.length > 0) {
            event = conflicts.filter(element => element.event_booking.id === eventId)[0]?.event_booking
            if (!event) {
                event = conflicts.filter(element => element.other_booking.id === eventId)[0].other_booking
            }
        }
        else {
            event = selectedPartyDescription.bookings.filter(event => event.id === eventId)[0]
        }
        dispatch(setPMSelectedEventDescription(event))
        dispatch(setPMEditableEventDate(new Date(event.date * 1000)))
        dispatch(setPMEditableEventStartTime(new Date(event.start_time * 1000)))
        dispatch(setPMEditableEventFinishTime(new Date(event.finish_time * 1000)))

        setChangingModal(true)
    }

    const onChangingExitClick = () => {
        setChangingModal(false)
    }

    const closeModal = () => {
        dispatch(setOpenedModal(''))
    }

    const onTimeConfirmClick = (startTime, finishTime) => {
        dispatch(setPMEditableEventStartTime(startTime))
        dispatch(setPMEditableEventFinishTime(finishTime))
        dispatch(setOpenedModal(''))
    }

    const onConfirmChangingClick = () => {
        setChangingModal(false)

        dispatch(editBookingThunk({
            booking_id: selectedEventId, room: selectedEventDescription.room,
            booking_date: editableEventDate, booking_time_start: editableEventStartTime,
            booking_time_finish: editableEventFinishTime, description: selectedEventDescription.description,
            event_type: selectedEventDescription.event_type.id, tenant: selectedEventDescription.tenant
        }))
    }

    const onConfirmDeleteAllConflictsClick = () => {
        dispatch(setOpenedModal(''))
        dispatch(resolveEventConflictsThunk({partyId: selectedPartyId}))
    }

    const navigateToParty = () => {
        onExitClick()
        navigate(`${PrivateRoutes.PartyChanging}/${selectedPartyId}`)
    }


    return (
        <div className='background'>
            {conflicts?.length > 0 && notification &&
                <DefaultModal
                    title={`Внимание! \n\nУ тебя есть 60 минут, чтоб разрешить конфликты с другими бронями, иначе твои конфликтные брони отменятся!`}
                    buttonText='Готово'
                    onButtonClick={() => setNotification(false)}
                />
            }
            {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={() => dispatch(setPMError(null))} />}
            {secError && <DefaultModal title={secError} buttonText='Готово' onButtonClick={() => dispatch(setError(null))} />}
            {status && <DefaultModal title='Успешно!' buttonText='Готово' onButtonClick={onModalCompleteExit}/>}
            {statusPM?.success && <DefaultModal title='Успешно!' buttonText='Готово' onButtonClick={onModalCompleteExit}/>}

            {deleteConfirmationModal &&
                <ConfirmationModal
                    title='Ты действительно хочешь отменить бронь?'
                    onYesButtonClick={onCurrentCancellingEventClick}
                    onNoButtonClick={onRejectCancellingEventClick}
                />
            }

            {changingModal &&
                <div className='background'>
                    <div className='schedule-changing-container'>
                        <div className='schedule-title'>
                            Изменение брони {selectedEventId}
                            <img onClick={onChangingExitClick} className='schedule-exit' src='/img/icons/х.svg' alt='X' />
                        </div>
                        <div className='schedule-buttons-wrapper'>
                            <FilterButton
                                title='Дата брони'
                                text={editableEventDate.toLocaleDateString()}
                                icon='Date'
                                modalName={'date'}
                            />
                            <FilterButton
                                title='Время брони'
                                text={`${formatTime(editableEventStartTime)} - ${formatTime(editableEventFinishTime)}`}
                                icon='Time'
                                modalName={'time'}
                            />
                        </div>
                        <div className='schedule-button'>
                            <Button text='Готово' onClick={onConfirmChangingClick} />
                        </div>
                    </div>
                </div>
            }

            {openedModal==='date' &&
                <DayPicker selectedDate={editableEventDate} onPick={(date) => {
                    dispatch(setPMEditableEventDate(date))
                }} closeModal={closeModal}/>
            }
            {openedModal==='time' &&
                <DropDownTimePicker date={editableEventDate} startTime={editableEventStartTime} finishTime={editableEventFinishTime} onConfirmClick={onTimeConfirmClick} closeModal={closeModal}/>
            }
            {openedModal===Modals.Confirmed &&
                <ConfirmationModal
                    title={user.role===Roles.USER ?
                        `Внимание! Все твои пересекающиеся события будут отменены. Скорректируй расписание или продолжи. \nПодтвердить?`
                        :
                        `Все пересекающиеся события у пользователей будут отменены! Скорректируй расписание мероприятия на время пересечений или продолжи. \nПодтвердить?`}
                    onYesButtonClick={onConfirmDeleteAllConflictsClick}
                    onNoButtonClick={closeModal}
                />
            }

            <div className='schedule-container'>
                <div className='schedule-title'>
                    {conflicts?.length > 0 ? 'Конфликты' : 'Расписание'}
                    <img onClick={adding ? navigateToParty : onExitClick} className='schedule-exit' src='/img/icons/х.svg' alt='X' />
                </div>
                <div className='schedule-table-wrapper'>
                    <Table
                        columns={columns}
                        rows={getEventsRows()}
                        onChangeClick={onChangingClick}
                        onCancelBookingClick={onCancelBookingClick}
                        indexId={indexOfEventId}
                        evenStroke={conflicts?.length > 0}
                    />
                </div>
                {conflicts?.length > 0 ?
                    <div className='schedule-buttons-wrapper'><Button text='Разрешить все конфликты' onClick={() => dispatch(setOpenedModal(Modals.Confirmed))}/></div>
                    :
                    <div className='schedule-buttons-wrapper'><Button text='Готово' onClick={navigateToParty}/></div>
                }
            </div>
        </div>
    );
}

export default Schedule;