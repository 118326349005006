import React, {useCallback, useEffect, useState} from 'react';

import Header from '../../components/Header/Header';
import BookingsRegistryByRooms from '../../components/BookingsRegistryByRooms/BookingsRegistryByRooms';

import './ViewerRoomsBrowse.css';
import {Modals} from "../../core/utils/constants";
import OfficePicker from "../../components/modals/OfficePicker/OfficePicker";
import {useDispatch, useSelector} from "react-redux";
import {setOpenedModal} from "../../core/store/slices/modalsSlice";
import {setOffice, setSelectedOfficeId} from "../../core/store/slices/reservationSlice";
import {fetchLocations} from "../../core/store/actions/reservationActions";


function ScreenViewerRoomsBrowse() {
    const dispatch = useDispatch();

    const { office, selectedOfficeId } = useSelector((state) => state.reservation);
    const { openedModal } = useSelector((state) => state.modals);

    const [searchText, setSearchText] = useState("")
    const onInputChange = (text) => {
        setSearchText(text)
    }

    const closeModal = useCallback(() => {
        dispatch(setOpenedModal(''));
    }, [dispatch]);

    const onOfficeClick = (officeId, officeName) => {
        dispatch(setSelectedOfficeId(officeId));
        dispatch(setOffice(officeName));
        dispatch(setOpenedModal(''));
    }

    useEffect(() => {
        dispatch(fetchLocations({}));

    },[])

    return (
        <div className='booking-management-screen'>
            {(openedModal === Modals.OfficePicker) && (
                <div className='reservation-office-picker-wrapper'>
                    <OfficePicker selectedOfficeId={selectedOfficeId} onPick={onOfficeClick} closeModal={closeModal} />
                </div>
            )}

            <Header title='Занятость переговорных комнат' onInputChange={onInputChange} withInput  selectedOffice={office} modalName={(openedModal === Modals.OfficePicker) ? '' : Modals.OfficePicker} />

            <div className='booking-management-main-wrapper'>
                <BookingsRegistryByRooms withOfficeFilter={true} officeFilterId={selectedOfficeId} searchText={searchText} disabledButtons={true}/>
            </div>
            <div className='qr-codes-panel'>
                <img src="/img/team_it_qr_code.JPG" width={150} alt="Team IT" />
                <img src="/img/icons/Team_IT.svg" width={150} alt="Team IT" />
            </div>
        </div>
    );
}


export default ScreenViewerRoomsBrowse;
