import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { fetchAllRoomsThunk } from "../../core/store/actions/roomsManagementActions";
import { PrivateRoutes } from "../../core/utils/constants";
import { addBreadCrumb } from '../../core/store/slices/navigationSlice';

import ColorRingLoader from "../../components/UI/ColorRingLoader/ColorRingLoader";
import Header from "../../components/Header/Header";
import Menu from "../../components/UI/Menu/Menu";
import BookingList from "../../components/BookingList/BookingList";

import './RoomsManagement.css'


function ScreenRoomsManagement() {
    const { loading, allRooms } = useSelector((state) => state.roomsManagement);

    const dispatch = useDispatch();

    const [managementSwitch, setManagementSwitch] = useState(false);
    const onSwitchChange = (bool, event) => {
        setManagementSwitch(bool);
    }

    const onItemClick = () => {
        dispatch(addBreadCrumb({ name: 'Управление переговорками', link: PrivateRoutes.MeetingRoomsManagement }));
    }

    const onEditClick = (roomId) => {
        dispatch(addBreadCrumb({ name: 'Управление переговорками', link: PrivateRoutes.MeetingRoomsManagement }));
        dispatch(addBreadCrumb({ name: `Описание переговорки`, link: PrivateRoutes.AdminRoomDescription + `/${roomId}` }));
    }

    const [searchText, setSearchText] = useState("")
    const onInputChange = (text) => {
        setSearchText(text)
    }

    useEffect(() => {
        dispatch(fetchAllRoomsThunk({searchText}))
    }, [searchText])

    function getRooms(type) {
        let rooms = [];

        if (type === 'unfilled') {
            rooms = Object.keys(allRooms)
                .filter((key) => allRooms[key].not_filled)
                .map((key) => ({ room_info: allRooms[key] }));

            rooms = rooms.filter(item => item);
        } else if (type === 'all') {
            rooms = Object.keys(allRooms).map((key) => ({ room_info: allRooms[key] }));
        }

        rooms.sort((a, b) => {
            if (a.room_info.city === b.room_info.city) {
                return (a.room_info.location < b.room_info.location) ? -1 : 1;
            }

            return (a.room_info.city < b.room_info.city) ? -1 : 1;
        });

        return rooms;
    }

    return (
        <div className='meeting-rooms-management-screen'>
            <Header title='Управление переговорками' onSwitchChange={onSwitchChange} onInputChange={onInputChange} withInput />
            <Menu />

            <div className='meeting-rooms-management-main-wrapper'>
                {loading && <ColorRingLoader />}
                {
                    managementSwitch ? (
                        <div className='meeting-rooms-management-rooms-list'>
                            {allRooms && <BookingList rooms={getRooms('unfilled')} routeTo={PrivateRoutes.AdminRoomDescription} onItemClick={onItemClick} onEditButtonClick={onEditClick} />}
                        </div>
                    ) : (
                        <div className='meeting-rooms-management-rooms-list'>
                            {allRooms && <BookingList rooms={getRooms('all')} routeTo={PrivateRoutes.AdminRoomDescription} onItemClick={onItemClick} onEditButtonClick={onEditClick} />}
                        </div>
                    )
                }
            </div>
        </div>
    );
}


export default ScreenRoomsManagement;
