import React, {memo} from 'react';
import './FilterButton.css';
import {setOpenedModal} from "../../../core/store/slices/modalsSlice";
import {useDispatch} from "react-redux";

function FilterButton({title, text, icon, modalName, disabled}) {
    const dispatch = useDispatch()
    const toggleModal = (modal) => {
        dispatch(setOpenedModal(modal))
    }

    return (
        <button
            className='filter-button'
            onClick={() => toggleModal(modalName)}
            disabled={disabled}
        >
            <div className='icon-container'>
                <img className='icon' src={`/img/icons/${icon}.svg`} alt={icon} />
            </div>
            <div className={'filter-description ' + (!disabled && 'active')}>
                <div className='filter-title'>
                    {title}
                </div>
                <div className='filter-text'>
                    {text}
                </div>
            </div>
        </button>
    )
}

export default memo(FilterButton)