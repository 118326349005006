import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modals } from '../../../core/utils/constants';
import { createBooking, fetchEvents } from '../../../core/store/actions/reservationActions';
import { formatTime } from '../../../core/utils/functions';
import { PrivateRoutes } from '../../../core/utils/constants';
import { setOpenedModal } from '../../../core/store/slices/modalsSlice';
import {
    clearDescriptionInfo,
    setDescription,
    setError,
    setHeadPerson,
    setSelectedRoomDescription,
    setStatus,
    setTopic
} from '../../../core/store/slices/reservationSlice';

import DefaultModal from '../../../components/modals/DefaultModal/DefaultModal';
import DescriptionSetter from '../../../components/modals/DescriptionSetter/DescriptionSetter';
import ColorRingLoader from '../../../components/UI/ColorRingLoader/ColorRingLoader';
import BookingListItem from '../../../components/BookingListItem/BookingListItem';
import FilterButton from '../../../components/UI/FilterButton/FilterButton';
import Header from '../../../components/Header/Header';
import Menu from "../../../components/UI/Menu/Menu";
import Button from '../../../components/UI/Button/Button';

import './RoomDescription.css';

function ScreenRoomDescription() {
    const { loading, error, status, date, startTime, finishTime, selectedRoomDescription, selectedRoomId, foundRooms, topic, description, headPerson, events } = useSelector((state) => state.reservation);
    const { openedModal } = useSelector((state) => state.modals);

    const [localStatus, setLocalStatus] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const closeModal = () => {
        dispatch(setOpenedModal(''));
    }

    const onModalExitClick = () => {
        dispatch(setOpenedModal(''));
    }

    const onBookButtonClick = () => {
        if (topic && description) {
            dispatch(createBooking({ selectedRoomId, date, startTime, finishTime, description, topic, headPerson }));
        } else {
            setLocalStatus('Сначала заполни тему и описание!');
            dispatch(setOpenedModal(Modals.Error));
        }
    }

    useEffect(() => {
        dispatch(fetchEvents({}))
        dispatch(clearDescriptionInfo())

        for (const roomsCatalog in foundRooms) {
            for (const room in foundRooms[roomsCatalog]) {
                if (foundRooms[roomsCatalog][room].room_info?.id === selectedRoomId) {
                    dispatch(setSelectedRoomDescription(foundRooms[roomsCatalog][room].room_info));
                    break;
                }
            }
        }
    }, [dispatch, foundRooms, selectedRoomId]);

    const onModalCompleteExit = () => {
        dispatch(setStatus(null));
        navigate(PrivateRoutes.Reservation);
    }

    const changeHeadPerson = (e) => {
        dispatch(setHeadPerson(e.target.value))
    }
    const changeTopic = (obj) => {
        let result = events.event_types?.filter(el => {
            return el.title === obj.value
        })
        dispatch(setTopic(result[0]))
    }
    const changeDescription = (e) => {
        dispatch(setDescription(e.target.value))
    }

    return (
        <div className='room-description-screen'>
            {(openedModal === Modals.Error) && <DefaultModal title={localStatus} buttonText='Готово' onButtonClick={onModalExitClick} />}
            {(openedModal === Modals.DescriptionSetter) &&
                <DescriptionSetter
                    topic={topic} description={description} headPerson={headPerson} events={events}
                    changeTopic={changeTopic} changeHeadPerson={changeHeadPerson} changeDescription={changeDescription} closeModal={closeModal}
                />
            }

            {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={() => dispatch(setError(null))} />}
            {status && <DefaultModal title='Переговорная комната забронирована!' buttonText='Готово' onButtonClick={onModalCompleteExit} />}

            <Header title='Бронирование переговорок' />
            <Menu />

            {
                loading ? (
                    <ColorRingLoader />
                ) : (
                    <div className='room-description-container'>
                        <div className='room-description-content'>
                            <div className='room-description-title'>
                                {date.toLocaleDateString()}

                                <span className='room-description-title-time'>НА {formatTime(startTime)}-{formatTime(finishTime)}</span>
                            </div>

                            <BookingListItem photo={selectedRoomDescription?.photo} title={selectedRoomDescription?.name} size={selectedRoomDescription?.capacity} />

                            <div className='room-description-text'>
                                Описание
                                <div>{selectedRoomDescription?.description}</div>
                            </div>
                            <div className='room-description-text'>
                                Техническое оснащение
                                <div>{selectedRoomDescription?.equipment}</div>
                            </div>

                            <FilterButton title='Описание мероприятия' text={topic ? topic.title : 'Выбери тему мероприятия'} icon='Description' modalName={Modals.DescriptionSetter} />
                        </div>

                        <div className='button-footer'>
                            <Button text='Забронировать' onClick={onBookButtonClick} />
                        </div>
                    </div>
                )
            }
        </div>
    );
}


export default ScreenRoomDescription;
