import { configureStore } from "@reduxjs/toolkit";

import authReducer from './slices/authSlice'
import reservationReducer from './slices/reservationSlice'
import modalsReducer from './slices/modalsSlice'
import bookingManagementReducer from "./slices/bookingManagementSlice";
import roomsManagementReducer from "./slices/roomsManagementSlice";
import partiesManagementReducer from "./slices/partiesManagementSlice";
import navigationSlice from "./slices/navigationSlice";
import paginationSlice from "./slices/paginationSlice";


export const store = configureStore({
    reducer: {
        auth: authReducer,
        reservation: reservationReducer,
        bookingManagement: bookingManagementReducer,
        roomsManagement: roomsManagementReducer,
        partiesManagement: partiesManagementReducer,

        modals: modalsReducer,
        navigation: navigationSlice,
        pagination: paginationSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
