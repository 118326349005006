import React, {useEffect, useRef} from 'react';
import './MyBookings.css';
import Booking from "./Booking/Booking";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchMyBookingsThunk,
} from "../../core/store/actions/reservationActions";
import {Link, useNavigate} from "react-router-dom";
import {setSelectedBookingId} from "../../core/store/slices/reservationSlice";
import {PrivateRoutes} from "../../core/utils/constants";
import {addBreadCrumb} from "../../core/store/slices/navigationSlice";


function MyBookings() {
    const {myBookings} = useSelector((state) => state.reservation)
    const {user} = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        dispatch(fetchMyBookingsThunk({user}))
    }, [])

    const scrollRef = useRef(null);

    const onWheel = e => {
        const container = scrollRef.current;
        const containerScrollPosition = scrollRef.current.scrollLeft;

        container.scrollTo({
            top: 0,
            left: containerScrollPosition + e.deltaY,
        });
    };

    const selectBooking = () => {
        dispatch(addBreadCrumb({ name: 'Бронирование', link: PrivateRoutes.Reservation }));
    }

    if (myBookings.length !== 0) return (
        <div className='your-bookings'>
            <div className='title'>Твои брони</div>
            <div className='bookings' ref={scrollRef} onWheel={onWheel}>
                {myBookings.map(booking =>
                    <Link key={booking.booking_info.id} to={`${PrivateRoutes.BookingDescription}/${booking.booking_info.id}`} onClick={selectBooking}>
                        <Booking
                            id={booking.booking_info.id}
                            photo={booking.room_info.photo}
                            title={booking.room_info.name}
                            subtitle={
                                `Забронирована на ${new Date(booking.booking_info.date*1000).toLocaleDateString()} 
                                 с ${new Date(booking.booking_info.start_time*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                                 до ${new Date(booking.booking_info.finish_time*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`
                            }
                        />
                    </Link>
                )}
            </div>
        </div>
    )
}

export default MyBookings
