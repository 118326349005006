import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { checkAuthWithCookieThunk, checkAuthWithHeaderThunk } from '../core/store/actions/authActions';
import {PrivateRoutes, PublicRoutes, Roles} from '../core/utils/constants';

import ColorRingLoader from '../components/UI/ColorRingLoader/ColorRingLoader';

import ScreenLogin from './Login/Login';
import ScreenViewerLogin from './ViewerLogin/ViewerLogin';
import ScreenReservation from './Reservation/Reservation';
import ScreenRoomsManagement from './RoomsManagement/RoomsManagement';
import ScreenRoomEditing from './RoomsManagement/RoomEditing/RoomEditing';
import ScreenRoomDescription from './Reservation/RoomDescription/RoomDescription';
import ScreenAdminRoomDescription from './RoomsManagement/AdminRoomDescription/AdminRoomDescription';
import ScreenBookingManagement from './BookingManagement/BookingManagement';
import ScreenBookingDescription from './Reservation/BookingDescription/BookingDescription';
import ScreenPartyManagement from "./PartyManagement/PartyManagement";
import ScreenPartyChanging from "./PartyManagement/PartyChanging/PartyChanging";
import ScreenViewerRoomsBrowse from "./ViewerRoomsBrowse/ViewerRoomsBrowse";


function ScreensRoot() {
    const { loading, user, accessToken, refreshToken, error } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        if (accessToken && refreshToken) {
            dispatch(checkAuthWithHeaderThunk({ refreshToken }));
        } else {
            dispatch(checkAuthWithCookieThunk({}));
        }
    }, []);

    if (error) {
        alert(error);
    }

    if (loading) {
        return <ColorRingLoader />;
    }

    if (!user) {
        return (
            <Routes>
                <Route path={PublicRoutes.SignIn} element={<ScreenLogin />} />
                <Route path={PublicRoutes.ViewerSignIn} element={<ScreenViewerLogin />} />

                <Route path='*' element={<Navigate to={PublicRoutes.SignIn} />} />
            </Routes>
        );
    }

    if (user.role === Roles.VIEWER) {
        return (
            <Routes>
                <Route path={PublicRoutes.ViewerSignIn} element={<ScreenViewerLogin/>}/>
                <Route path={PrivateRoutes.BookingRoomsBrowse} element={<ScreenViewerRoomsBrowse/>}/>
                <Route path='*' element={<Navigate to={PrivateRoutes.BookingRoomsBrowse}/>}/>
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path={PublicRoutes.SignIn} element={<ScreenLogin/>}/>
            <Route path={PublicRoutes.ViewerSignIn} element={<ScreenViewerLogin/>}/>
            <Route path={PrivateRoutes.Reservation} element={<ScreenReservation/>}/>
            <Route path={PrivateRoutes.BookingDescription + '/:id'} element={<ScreenBookingDescription/>}/>
            <Route path={PrivateRoutes.RoomDescription} element={<ScreenRoomDescription/>}/>
            <Route path={PrivateRoutes.BookingManagement} element={<ScreenBookingManagement/>}/>
            <Route path={PrivateRoutes.MeetingRoomsManagement} element={<ScreenRoomsManagement/>}/>
            <Route path={PrivateRoutes.AdminRoomDescription + '/:id'} element={<ScreenAdminRoomDescription/>}/>
            <Route path={PrivateRoutes.RoomEditing + '/:id'} element={<ScreenRoomEditing/>}/>
            <Route path={PrivateRoutes.PartyManagement} element={<ScreenPartyManagement/>}/>
            <Route path={PrivateRoutes.PartyChanging + '/:id'} element={<ScreenPartyChanging/>}/>

            <Route path='*' element={<Navigate to={PrivateRoutes.Reservation}/>}/>
        </Routes>
    );
}


export default ScreensRoot;
