import React from 'react';
import './DayPicker.css';
import Button from "../../UI/Button/Button";
import DatePicker, {setDefaultLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';
import WeekDays from "../../UI/WeekDays/WeekDays";
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {Keys} from "../../../core/utils/constants";
import {getCurrentMoscowTime} from "../../../core/utils/functions";
setDefaultLocale(ru)

function DayPicker({selectedDate, onPick, closeModal}) {
    useKeyUp(Keys.KEY_NAME_ESC, closeModal)
    useKeyUp(Keys.KEY_NAME_ENTER, closeModal)

    return (
        <div className='background'>
            <div className='date-container'>
                <WeekDays />
                <div className='date-calendar'>
                    <DatePicker
                        selected={selectedDate}
                        minDate={getCurrentMoscowTime()}
                        onChange={onPick}
                        monthsShown={5}
                        disabledKeyboardNavigation
                        inline
                    />
                </div>
                <div className='button-footer'>
                    <Button text='Готово' onClick={closeModal}/>
                </div>
            </div>
        </div>
    )
}

export default DayPicker
