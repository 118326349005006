import {createSlice} from '@reduxjs/toolkit'
import {
    fetchBookingsByRoomsThunk,
    fetchBookingsInOneRoomThunk,
    fetchPaginateBookingsThunk, fineBookingThunk
} from "../actions/bookingManagementActions";
import {getCurrentMoscowTime} from "../../utils/functions";

const bookingManagementSlice = createSlice({
    name: 'bookingManagement',
    initialState: {
        selectedRoomBookings: null,

        paginateBookings: [],
        pageCount: 1,
        bookingsByRooms: [],
        selectedRoomId: null,
        bookingsInOneRoom: [],

        date: getCurrentMoscowTime(),

        editableDate: getCurrentMoscowTime(),
        editableStartTime: getCurrentMoscowTime(),
        editableFinishTime: getCurrentMoscowTime(),
        editableOffice: {id: null, name: null},
        editableRoom: {id: null, name: null},

        loading: false,
        error: null,
        status: null,
    },
    reducers: {
        setSelectedRoomBookings(state, action) {
            state.selectedRoomBookings = action.payload
        },
        setDate(state, action) {
            state.date = action.payload
        },
        setEditableDate(state, action) {
            state.editableDate = action.payload
        },
        setEditableStartTime(state, action) {
            state.editableStartTime = action.payload
        },
        setEditableFinishTime(state, action) {
            state.editableFinishTime = action.payload
        },
        setEditableOffice(state, action) {
            state.editableOffice = action.payload
        },
        setEditableRoom(state, action) {
            state.editableRoom = action.payload
        },
        setSelectedRoomId(state, action) {
            state.selectedRoomId = action.payload
        },
        setBMStatus(state, action) {
            state.status = action.payload
        },
        setBookingsByRooms(state, action) {
            state.bookingsByRooms = action.payload
        },
    },
    extraReducers: {

        [fetchPaginateBookingsThunk.pending]: (state) => {
            state.loading = true
        },
        [fetchPaginateBookingsThunk.fulfilled]: (state, {payload}) => {
            state.paginateBookings = payload.bookings
            state.pageCount = payload.page_count
            state.loading = false
        },
        [fetchPaginateBookingsThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [fetchBookingsByRoomsThunk.pending]: (state) => {
            state.loading = true
        },
        [fetchBookingsByRoomsThunk.fulfilled]: (state, {payload}) => {
            state.bookingsByRooms = payload
            state.loading = false
        },
        [fetchBookingsByRoomsThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [fetchBookingsInOneRoomThunk.pending]: (state) => {
            state.loading = true
            state.bookingsInOneRoom = []
        },
        [fetchBookingsInOneRoomThunk.fulfilled]: (state, {payload}) => {
            state.bookingsInOneRoom = payload.rooms[0].bookings
            state.loading = false
        },
        [fetchBookingsInOneRoomThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [fineBookingThunk.pending]: (state) => {
            state.loading = true
            state.status = null
        },
            [fineBookingThunk.fulfilled]: (state, {payload}) => {
            state.status = payload
            state.loading = false
        },
            [fineBookingThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },
    }
})

export const {setDate, setBMStatus, setSelectedRoomBookings, setBookingsByRooms, setEditableDate, setEditableStartTime, setEditableFinishTime, setEditableOffice, setSelectedRoomId, setEditableRoom} = bookingManagementSlice.actions

export default bookingManagementSlice.reducer