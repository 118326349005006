import React, {memo} from 'react';
import './InfiniteScroller.css'
import InfiniteScrollLoop from "../InfiniteScroll/InfiniteScroll";

function InfiniteScroller({data, onSelect, defaultIndex = 0}) {
    const reformerToIndex = (element) => {
        const index = data.findIndex(obj => obj.id == element.id)
        if (index === 0) {
            onSelect(data.length-1)
        }
        else {
            onSelect(index-1)
        }
    }

    return (
        <InfiniteScrollLoop
            verticalScroll={true}
            onSelect={reformerToIndex}
            backup={3}
            defaultIndex={defaultIndex}
        >
            {data.map((element, index) => {
                return (
                    <div key={element.id} className="scroll-content-container">
                        <div id={`${element.id}`} className="scroll-content-number">
                            {element.value}
                        </div>
                    </div>
                )
            })}
        </InfiniteScrollLoop>
    );
}

export default memo(InfiniteScroller);
