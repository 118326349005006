import { createSlice } from '@reduxjs/toolkit'


const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        breadCrumbs: [],
    },
    reducers: {
        addBreadCrumb(state, action) {
            state.breadCrumbs.push(action.payload);
        },
        removeLastBreadCrumb(state) {
            state.breadCrumbs.pop();
        },
        removeBreadCrumbsFromGiven(state, action) {
            const newLastCrumbIndex = state.breadCrumbs.findIndex((crumb) => crumb.name === action.payload.name);
            if (newLastCrumbIndex === undefined) { return; }
            state.breadCrumbs = state.breadCrumbs.slice(0, newLastCrumbIndex);
        },
        removeAllBreadCrumbs(state) {
            state.breadCrumbs = [];
        }
    }
});


export const { addBreadCrumb, removeLastBreadCrumb, removeBreadCrumbsFromGiven, removeAllBreadCrumbs } = navigationSlice.actions;

export default navigationSlice.reducer;
