import React, { useEffect } from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {setSelectedRoomId} from "../../../core/store/slices/roomsManagementSlice";
import { PrivateRoutes } from "../../../core/utils/constants";
import { addBreadCrumb } from '../../../core/store/slices/navigationSlice';

import BookingListItem from "../../../components/BookingListItem/BookingListItem";
import Header from "../../../components/Header/Header";
import ColorRingLoader from "../../../components/UI/ColorRingLoader/ColorRingLoader";
import Button from "../../../components/UI/Button/Button";
import Menu from "../../../components/UI/Menu/Menu";
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';

import './AdminRoomDescription.css';
import {fetchSelectedRoomThunk} from "../../../core/store/actions/roomsManagementActions";


function ScreenAdminRoomDescription() {
    const { loading, selectedRoomDescription } = useSelector((state) => state.roomsManagement);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams()

    const onModalExitClick = () => {
        navigate(PrivateRoutes.BookingManagement);
    }

    const onEditingButtonClick = () => {
        dispatch(addBreadCrumb({ name: `Описание переговорки`, link: `${PrivateRoutes.AdminRoomDescription}/${id}`}));
    }

    useEffect(() => {
        dispatch(setSelectedRoomId(Number(id)))
        dispatch(fetchSelectedRoomThunk({selectedRoomId: Number(id)}))
    }, []);

    return (
        <div className='room-description-screen'>
            <Header title='Управление переговорками' />
            <BreadCrumbs />
            <Menu />

            {loading && <ColorRingLoader />}

            <div className='room-description-container'>
                <div className='room-description-title'>
                    {selectedRoomDescription?.room_info?.name}
                </div>

                <div className='room-description-content'>
                    <BookingListItem photo={selectedRoomDescription?.photo} title={selectedRoomDescription?.name} size={selectedRoomDescription?.capacity} />

                    <div className='room-description-text'>
                        Описание
                        <div>{selectedRoomDescription?.description}</div>
                    </div>
                    <div className='room-description-text'>
                        Техническое оснащение
                        <div>{selectedRoomDescription?.equipment}</div>
                    </div>
                </div>

                <div className='buttons-footer'>
                    <Link to={`${PrivateRoutes.RoomEditing}/${id}`} onClick={onEditingButtonClick}><Button text='Изменить' color='white' /></Link>
                    <Button text='Готово' onClick={onModalExitClick} />
                </div>
            </div>
        </div>
    );
}


export default ScreenAdminRoomDescription;
