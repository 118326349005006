import React, {useEffect, useState} from 'react';
import './EditBooking.css'
import FilterButton from "../../UI/FilterButton/FilterButton";
import {Keys, Modals} from "../../../core/utils/constants";
import {formatTime} from "../../../core/utils/functions";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../UI/Button/Button";
import {
    setEditableDate,
    setEditableFinishTime, setEditableOffice, setEditableRoom,
    setEditableStartTime
} from "../../../core/store/slices/bookingManagementSlice";
import DayPicker from "../DayPicker/DayPicker";
import {setOpenedModal} from "../../../core/store/slices/modalsSlice";
import OfficePicker from "../OfficePicker/OfficePicker";
import RoomPicker from "../RoomPicker/RoomPicker";
import ReservationService from "../../../core/services/ReservationService";
import DropDownTimePicker from "../DropDownTimePicker/DropDownTimePicker";
import DefaultModal from "../DefaultModal/DefaultModal";
import {fetchSelectedBookingThunk} from "../../../core/store/actions/reservationActions";
import {useKeyUp} from "../../../core/hooks/useKeyUp";

function EditBooking({onExitClick}) {

    const {selectedBookingId, selectedBookingDescription, locations} = useSelector((state) => state.reservation)
    const {editableDate, editableStartTime, editableFinishTime, editableOffice, editableRoom} = useSelector((state) => state.bookingManagement)
    const { openedModal } = useSelector((state) => state.modals)

    const dispatch = useDispatch()

    const [status, setStatus] = useState('')

    const [rooms, setRooms] = useState([])

    useKeyUp(Keys.KEY_NAME_ESC, openedModal==='' ? onExitClick: undefined)

    useEffect(() => {
        dispatch(setEditableDate(new Date(selectedBookingDescription?.booking_info.date * 1000)))
        dispatch(setEditableStartTime(new Date(selectedBookingDescription?.booking_info.start_time * 1000)))
        dispatch(setEditableFinishTime(new Date(selectedBookingDescription?.booking_info.finish_time * 1000)))

        let officeName
        locations.map((city) => {
            city.sublocations.map((office) => {
                if (office.id===selectedBookingDescription?.room_info.location)
                    officeName = office.name
            })
        })

        dispatch(setEditableOffice({id: selectedBookingDescription?.room_info.location, name: officeName}))
        dispatch(setEditableRoom({id: selectedBookingDescription?.room_info?.id, name: selectedBookingDescription?.room_info?.name}))

    }, [])
    
    const closeModal = () => {
        dispatch(setOpenedModal(''))
    }

    const onOfficeClick = (officeId, officeName) => {
        dispatch(setEditableOffice({id: officeId, name: officeName}))
        dispatch(setOpenedModal(''))
    }

    const onRoomClick = (roomId, roomName) => {
        dispatch(setEditableRoom({id: roomId, name: roomName}))
        dispatch(setOpenedModal(''))
    }

    const onTimeConfirmClick = (startTime, finishTime) => {
        dispatch(setEditableStartTime(startTime))
        dispatch(setEditableFinishTime(finishTime))
        dispatch(setOpenedModal(''))
    }

    const onConfirmClick = async () => {
        if ([selectedBookingDescription, editableRoom.id, editableDate, editableStartTime, editableFinishTime].some((el) => el === null)) {
            setStatus('Данные введены некорректно')
            dispatch(setOpenedModal(Modals.Error))
            return
        }
        try {
            const response = await ReservationService.editBooking(
                selectedBookingDescription.booking_info.id,
                editableRoom.id,
                editableDate.setUTCHours(0,0,0,0),
                editableStartTime.getTime(),
                editableFinishTime.getTime(),
                selectedBookingDescription.booking_info.description,
                selectedBookingDescription.booking_info.event_type.id,
                selectedBookingDescription.booking_info.tenant,
            )
            dispatch(setOpenedModal(Modals.Confirmed))
        } catch (error) {
            setStatus(error.response.data.message)
            dispatch(setOpenedModal(Modals.Error))
        }
    }
    
    const onClickLastConfirmButton = () => {
        dispatch(setOpenedModal(''))
        onExitClick()
        dispatch(fetchSelectedBookingThunk({selectedBookingId}))
    }

    useEffect(() => {
        async function fetchRooms() {
            if (editableOffice.id !== null) {
                const response = await ReservationService.getRoomInfo([], editableOffice.id)
                if (response.data) {
                    const _rooms = Object.keys(response.data).map((key) => response.data[key])
                    setRooms(_rooms)
                    if (!Object.keys(response.data).includes(editableRoom?.id.toString())) {
                        dispatch(setEditableRoom({id: null, name: 'Выберите переговорку'}))
                    }
                }
            }
        }
        fetchRooms();
    },[editableOffice])



    return (
        <div className='background'>
            {openedModal===Modals.Confirmed && <DefaultModal title='Бронирование успешно изменено!' buttonText='Готово' onButtonClick={onClickLastConfirmButton} />}
            {openedModal===Modals.Error && <DefaultModal title={status} buttonText='Готово' onButtonClick={closeModal} />}

            {openedModal===Modals.DatePicker && <DayPicker selectedDate={editableDate} onPick={(date) => dispatch(setEditableDate(date))} closeModal={closeModal}/>}
            {openedModal===Modals.OfficePicker && <div className='edit-booking-office-wrapper'><OfficePicker selectedOfficeId={editableOffice.id} onPick={onOfficeClick} closeModal={closeModal}/></div>}
            {openedModal===Modals.Rooms && <div className='edit-booking-office-wrapper'><RoomPicker rooms={rooms} selectedRoomId={editableRoom.id} onPick={onRoomClick} closeModal={closeModal}/></div>}
            {openedModal===Modals.DropDownTimePicker && <DropDownTimePicker date={editableDate} startTime={editableStartTime} finishTime={editableFinishTime} onConfirmClick={onTimeConfirmClick} closeModal={closeModal}/>}
            <div className='edit-chooser-panel'>

                <img onClick={onExitClick} className='edit-chooser-panel-exit' src='/img/icons/х.svg' alt='X'/>
                <div className='chooser-panel-wrapper'>
                    <div className='chooser-panel-title'>Изменить бронирование</div>
                    <FilterButton
                        title='Офис'
                        text={editableOffice.name}
                        icon='tech'
                        modalName={Modals.OfficePicker}
                    />
                    <FilterButton
                        title='Переговорка'
                        text={editableRoom.name}
                        icon='People'
                        modalName={Modals.Rooms}
                    />
                    <FilterButton
                        title='Дата брони'
                        text={editableDate.toLocaleDateString()}
                        icon='Date'
                        modalName={Modals.DatePicker}
                    />
                    <FilterButton
                        title='Время брони'
                        text={`${formatTime(editableStartTime)} - ${formatTime(editableFinishTime)}`}
                        icon='Time'
                        modalName={Modals.DropDownTimePicker}
                    />
                    <Button text='Готово' onClick={onConfirmClick}/>
                </div>
            </div>
        </div>
    );
}

export default EditBooking;