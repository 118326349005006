import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchBookingsByRoomsThunk } from '../../core/store/actions/bookingManagementActions';
import {setBookingsByRooms, setDate, setSelectedRoomId} from '../../core/store/slices/bookingManagementSlice';

import Table from '../UI/Table/Table';
import TimeLine from '../TimeLine/TimeLine';
import BookingsByRoomRegistry from '../modals/BookingsByRoomRegistry/BookingsByRoomRegistry';

import './BookingsRegistryByRooms.css';
import {getCurrentMoscowTime} from "../../core/utils/functions";
import {setOffice, setSelectedOfficeId} from "../../core/store/slices/reservationSlice";
import {getFilterOffice, putFilterOffice} from "../../core/store/utils/functions";


function BookingsRegistryByRooms({searchText, disabledButtons, officeFilterId, withOfficeFilter}) {
    const dispatch = useDispatch();
    const {locations} = useSelector((state) => state.reservation)
    const { date, bookingsByRooms, loading } = useSelector((state) => state.bookingManagement);

    const columns = ['Офис', 'Переговорка', 'Вместимость', 'Город', 'ID'];

    useEffect(() => {
        dispatch(fetchBookingsByRoomsThunk({date, searchText, officeFilterId}))
    }, [date, searchText, officeFilterId])

    useEffect(() => {
        let interval;
        function startTimer() {
            interval = setInterval(async () => {
                await dispatch(fetchBookingsByRoomsThunk({date, searchText, officeFilterId}))
            }, 5000)
        }
        startTimer()
        return () => clearInterval(interval)
    }, [date, searchText, officeFilterId])

    const [openedLocalModal, setOpenedLocalModal] = useState(false);

    const nextDate = () => {
        const newDate = new Date(date.getTime() + (24 * 60 * 60 * 1000))
        dispatch(setDate(newDate))
    }

    const prevDate = () => {
        const newDate = new Date(date.getTime() - (24 * 60 * 60 * 1000))
        dispatch(setDate(newDate))
    }

    const [bookings, setBookings] = useState([]);
    const [timeLineItems, setTimeLineItems] = useState([]);
    const [timeLineGroups, setTimeLineGroups] = useState([]);

    useEffect(() => {
        let rows = [];
        bookingsByRooms.map((element, index) =>
            rows.push([
                element.location.name,
                element.name,
                element.capacity,
                element.city.name,
                element.id
            ])
        );
        setBookings(rows);

        let items = []
        const colors = [
            "#929397", "#68A2AA", "#9BD5C1", "#AAE0E7", "#C679A5", "#C987DC",
            "#CDD6C5", "#D5C2C6", "#DE5CA1", "#E4BCEE", "#F38780", "#FDEFC6",
        ]
        bookingsByRooms.map((room, room_index) =>
            room.bookings.map((booking, ind) => {
                items.push({
                    id: booking.id,
                    group: room_index+1,
                    start_time: new Date((getCurrentMoscowTime()).setHours(new Date(booking.start_time * 1000).getHours(), new Date(booking.start_time * 1000).getMinutes(), 0, 0)),
                    end_time: new Date((getCurrentMoscowTime()).setHours(new Date(booking.finish_time * 1000).getHours(), new Date(booking.finish_time * 1000).getMinutes(), 0, 0)),
                    itemProps: {
                        style: {
                            background: colors[ind % colors.length]
                        }
                    }
                })
            })
        )
        setTimeLineItems(items);

        const groups = bookingsByRooms.map((room, room_index) => ({ id: room_index + 1 }));

        setTimeLineGroups(groups);
    }, [bookingsByRooms]);

    useEffect(() => {
        if (withOfficeFilter && officeFilterId===null) {
            const {filterOfficeId, filterOfficeName} = getFilterOffice()
            if (filterOfficeId && filterOfficeName) {
                dispatch(setSelectedOfficeId(filterOfficeId))
                dispatch(setOffice(filterOfficeName))
            }
        }
    },[])

    useEffect(() => {
        if (officeFilterId && getFilterOffice().filterOfficeId !== officeFilterId) {
            let officeName
            locations.map((city) => {
                city.sublocations.map((office) => {
                    if (office.id===officeFilterId)
                        officeName = office.name
                })
            })
            putFilterOffice(officeFilterId, officeName)
        }
    },[officeFilterId])

    const onRowClick = (roomId) => {
        dispatch(setSelectedRoomId(roomId));
        setOpenedLocalModal(true);
    }

    const exitClick = () => {
        setOpenedLocalModal(false);
    }

    return (
        <div>
            {openedLocalModal && <BookingsByRoomRegistry onExitClick={exitClick} />}

            <div className='booking-management-date-picker'>
                <svg style={{ transform: 'rotate(180deg)' }} onClick={prevDate} width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M8.1543 6.5L0.538913 12.1292L0.538913 0.870835L8.1543 6.5Z' fill='black' />
                </svg>

                {date.toLocaleDateString()}

                <svg onClick={nextDate} width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M8.1543 6.5L0.538913 12.1292L0.538913 0.870835L8.1543 6.5Z' fill='black' />
                </svg>
            </div>
            <div className='booking-management-main-table'>
                <div className='booking-management-table-wrapper'>
                    <Table indexId={4} onRowClick={disabledButtons ? undefined : onRowClick} columns={columns} rows={bookings} nowrap={true} />
                </div>

                {!openedLocalModal && timeLineGroups.length > 0 &&
                    <div className='booking-management-timeline-wrapper'>
                        <TimeLine groups={timeLineGroups} items={timeLineItems} disabledButtons={disabledButtons}/>
                    </div>
                }
            </div>
        </div>
    );
}


export default BookingsRegistryByRooms;
