import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { removeBreadCrumbsFromGiven } from '../../core/store/slices/navigationSlice';


import './BreadCrumbs.css';


function BreadCrumbs() {
    const { breadCrumbs } = useSelector((state) => state.navigation);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function navigateToCrumb(crumb) {
        dispatch(removeBreadCrumbsFromGiven(crumb));

        navigate(crumb.link);
    }

    return (
        <div className='bread-crumbs'>
            {breadCrumbs.map((crumb, index) => (
                <span key={index}>
                    <span className='crumbs-separator'>/</span>
                    <span className='crumb' onClick={() => { navigateToCrumb(crumb); }}>{crumb.name}</span>
                </span>
            ))}
        </div>
    );
}


export default BreadCrumbs;
