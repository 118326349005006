import React from 'react';

import { formatTime } from '../../core/utils/functions';

import './BookingListItem.css'
import {Link} from "react-router-dom";
import {PrivateRoutes} from "../../core/utils/constants";


function BookingListItem({ id, photo, title, size = 0, timesButtons, onTimeButtonClick, onEditButtonClick, onClick, routeTo }) {
    const onClicked = () => {
        if (onClick) {
            onClick(id);
        }
    }

    const onEditClick = () => {
        if (onEditButtonClick) {
            onEditButtonClick(id);
        }
    }

    return (
        <div className='booking-list-item'>
            <div className='booking-list-item-container'>
                {routeTo ?
                    <Link onClick={!onClick ? (e) => e.preventDefault() : () => onClicked()} to={`${routeTo}/${id}`}>
                        <div>
                            {photo ? <img className='booking-list-item-pic' src={photo.base64} alt='Room' /> : <img className='booking-list-item-pic' src='/img/rooms/default_room.png' alt={`${photo}`} />}
                            <div className='booking-list-item-size'>
                                <img className='booking-list-item-size-pic' src='/img/icons/flag.svg' alt={`${size}`} />
                                <span className='booking-list-item-size-text'>до {size.length===1 ? size+'\u00A0' : size}<br />чел</span>
                            </div>
                            <div className='booking-list-item-title'>
                                {title}
                            </div>
                        </div>
                    </Link>
                    :
                    <div onClick={!onClick ? (e) => e.preventDefault() : () => onClicked()}>
                        {photo ? <img className='booking-list-item-pic' src={photo.base64} alt='Room' /> : <img className='booking-list-item-pic' src='/img/rooms/default_room.png' alt={`${photo}`} />}
                        <div className='booking-list-item-size'>
                            <img className='booking-list-item-size-pic' src='/img/icons/flag.svg' alt={`${size}`} />
                            <span className='booking-list-item-size-text'>до {size.length===1 ? size+'\u00A0' : size}<br />чел</span>
                        </div>
                        <div className='booking-list-item-title'>
                            {title}
                        </div>
                    </div>
                }

                {onEditButtonClick && (
                    <div className='booking-list-item-edit-buttons'>
                        <Link className='booking-list-item-button-edit' to={`${PrivateRoutes.RoomEditing}/${id}`} onClick={() => onEditClick()}>изменить</Link>
                    </div>
                )}
            </div>
            {timesButtons && (
                <div className='booking-list-panel-times-buttons'>
                    {timesButtons.map((button, index) => (
                        <div key={index} className='booking-list-times-button' onClick={() => onTimeButtonClick(button.start_time * 1000, button.finish_time * 1000, id)}>
                            {formatTime(new Date(button.start_time * 1000))}-{formatTime(new Date(button.finish_time * 1000))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}


export default BookingListItem;
