import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { viewerSignInThunk } from '../../core/store/actions/authActions';
import { PrivateRoutes } from '../../core/utils/constants';

import './ViewerLogin.css';


const ScreenViewerLogin = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, error } = useSelector((state) => state.auth);

    useEffect(() => {
        if (user) {
            navigate(PrivateRoutes.BookingManagement);
        }
    }, [navigate, user]);

    function submit() {
        dispatch(viewerSignInThunk({ login, password }));
    }

    return (
        <div className='box'>
            <div className='box-form'>
                <div className='box-login-tab'></div>
                <div className='box-login-title'>
                    <div className='i i-login'></div>
                    <h2>Авторизация</h2>
                </div>
                <div className='box-login'>
                    <div className='fieldset-body' id='login_form'>
                        <p className='field'>
                            <label htmlFor='user'>Логин</label>
                            <input className='viewer-login-login' type='text' id='user' name='user' title='Login' onChange={e => setLogin(e.target.value)} value={login} />
                        </p>
                        <p className='field'>
                            <label htmlFor='pass'>Пароль</label>
                            <input className='viewer-login-password' type='password' id='pass' name='pass' title='Password' onChange={e => setPassword(e.target.value)} value={password} />
                        </p>

                        <input className='viewer-login-submit' type='submit' id='do_login' value='Вход' onClick={submit} />

                        {error && <div>{error}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ScreenViewerLogin;
