import { createSlice } from '@reduxjs/toolkit';


const modalsSlice = createSlice({
    name: 'modals',
    initialState: {
        openedModal: '',
    },
    reducers: {
        setOpenedModal(state, action) {
            state.openedModal = action.payload;
        },
    }
});


export const { setOpenedModal } = modalsSlice.actions;

export default modalsSlice.reducer;
