import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
    cancelBooking,
    fetchLocations,
    fetchSelectedBookingThunk,
    returnBooking
} from "../../../core/store/actions/reservationActions";
import { bookingStatus, PrivateRoutes } from "../../../core/utils/constants";
import { Roles } from "../../../core/utils/constants";
import {setError, setSelectedBookingId, setStatus} from "../../../core/store/slices/reservationSlice";

import ColorRingLoader from "../../../components/UI/ColorRingLoader/ColorRingLoader";
import DefaultModal from "../../../components/modals/DefaultModal/DefaultModal";
import Header from "../../../components/Header/Header";
import BookingListItem from "../../../components/BookingListItem/BookingListItem";
import Button from "../../../components/UI/Button/Button";
import Menu from "../../../components/UI/Menu/Menu";
import EditBooking from "../../../components/modals/EditBooking/EditBooking";
import ConfirmationModal from "../../../components/modals/ConfirmationModal/ConfirmationModal";
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';

import './BookingDescription.css'
import {getCurrentMoscowTime} from "../../../core/utils/functions";
import {fineBookingThunk} from "../../../core/store/actions/bookingManagementActions";
import {setBMStatus} from "../../../core/store/slices/bookingManagementSlice";


function ScreenBookingDescription() {
    const { error, status, loading, selectedBookingDescription, selectedBookingId } = useSelector((state) => state.reservation);
    const { error: secError, status: secStatus } = useSelector((state) => state.bookingManagement);
    const { user } = useSelector((state) => state.auth);

    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(setSelectedBookingId(Number(id)))
        dispatch(fetchSelectedBookingThunk({ selectedBookingId: Number(id) }))
        dispatch(fetchLocations({}));
    }, []);

    const [localOpenModal, setLocalOpenModal] = useState('');

    const onExitClick = () => {
        setLocalOpenModal('');
    }

    const onReturnBookingClick = () => {
        setLocalOpenModal('Вернуть');
    }

    const onCancelBookingClick = () => {
        setLocalOpenModal('Отменить');
    }

    const onFineBookingClick = () => {
        setLocalOpenModal('Оштрафовать');
    }

    const onConfirmNavigate = () => {
        if ([Roles.ADMIN, Roles.SUPERADMIN].includes(user.role)) {
            navigate(PrivateRoutes.BookingManagement);
        } else {
            navigate(PrivateRoutes.Reservation);
        }
    }

    const onConfirmCancelingBookingClick = () => {
        setLocalOpenModal('');
        dispatch(cancelBooking({ selectedBookingId }));
    }

    const onConfirmReturningBookingClick = () => {
        setLocalOpenModal('');
        dispatch(returnBooking({ selectedBookingId }));
    }

    const onRejectCancelingBookingClick = () => {
        setLocalOpenModal('');
    }

    const onModalCompleteExit = () => {
        if (status) {
            dispatch(setStatus(null));
        }
        if (secStatus) {
            dispatch(setBMStatus(null));
        }
        onConfirmNavigate()
    }

    const onConfirmFiningBookingClick = () => {
        setLocalOpenModal('');
        dispatch(fineBookingThunk({ selectedBookingId }));
    }

    const onRejectFiningBookingClick = () => {
        setLocalOpenModal('');
    }

    function formatTime(time) {
        return new Date(time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    if (loading) {
        return <ColorRingLoader />;
    }

    return (
        <div className='my-booking-description-screen'>
            {(localOpenModal === 'Отменить') && <ConfirmationModal title='Ты действительно хочешь отменить бронь?' onYesButtonClick={onConfirmCancelingBookingClick} onNoButtonClick={onRejectCancelingBookingClick} />}
            {(localOpenModal === 'Вернуть') && <ConfirmationModal title='Ты действительно хочешь вернуть бронь к активной?' onYesButtonClick={onConfirmReturningBookingClick} onNoButtonClick={onRejectCancelingBookingClick} />}
            {(localOpenModal === 'Оштрафовать') && <ConfirmationModal title='Ты действительно хочешь оштрафовать бронирующего?' onYesButtonClick={onConfirmFiningBookingClick} onNoButtonClick={onRejectFiningBookingClick} />}

            {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={() => dispatch(setError(null))} />}
            {status && <DefaultModal title='Статус брони изменен!' buttonText='Готово' onButtonClick={onModalCompleteExit} />}
            {secStatus && <DefaultModal title='Успешно!' buttonText='Готово' onButtonClick={onModalCompleteExit} />}

            {(localOpenModal === 'Изменить') && <EditBooking onExitClick={onExitClick} />}

            <Header title='Бронирование переговорок' />
            <BreadCrumbs />
            <Menu />

            <div className='my-booking-description-wrapper'>
                <div className='my-booking-description-container'>
                    <div className='my-booking-description-content'>
                        <div className='booking-header'>
                            <div className='my-booking-description-subtitle'>
                                <div className='my-booking-description-title'>
                                    Бронирование {selectedBookingId}
                                </div>
                                {new Date(selectedBookingDescription?.booking_info?.date * 1000).toLocaleDateString()}
                                <span className='my-booking-description-subtitle-time'>
                                    НА {formatTime(selectedBookingDescription?.booking_info?.start_time)} - {formatTime(selectedBookingDescription?.booking_info?.finish_time)}
                                </span>
                            </div>
                            <div className='fine-button'>
                                {
                                    selectedBookingDescription?.booking_info?.status === bookingStatus.ongoing
                                    && [Roles.ADMIN, Roles.SUPERADMIN].includes(user.role)
                                    && <Button text='Не явились, оштрафовать' color='black' onClick={onFineBookingClick}/>
                                }
                            </div>
                        </div>

                        <BookingListItem id={selectedBookingId} photo={selectedBookingDescription?.room_info?.photo} title={selectedBookingDescription?.room_info?.name} size={selectedBookingDescription?.room_info?.capacity} />

                        <div className='my-booking-description-text'>
                            Описание
                            <div>{selectedBookingDescription?.room_info?.description}</div>
                        </div>
                        <div className='my-booking-description-text'>
                            Техническое оснащение
                            <div>{selectedBookingDescription?.room_info?.equipment}</div>
                        </div>

                        {[Roles.ADMIN, Roles.SUPERADMIN].includes(user.role) && (
                            <div className='my-booking-description-text'>
                                Информация о брони
                                <div>
                                    <table>
                                        <tbody>
                                            <tr><td>Статус брони: </td><td>{selectedBookingDescription?.booking_info?.status}</td></tr>
                                            <tr><td>Инициатор: </td><td>{selectedBookingDescription?.booking_info?.attendee?.username}</td></tr>
                                            <tr><td>Тип мероприятия: </td><td>{selectedBookingDescription?.booking_info?.event_type?.title}</td></tr>
                                            {selectedBookingDescription?.booking_info?.attendee?.username!==selectedBookingDescription?.booking_info?.tenant
                                                && <tr><td>Для кого: </td><td>{selectedBookingDescription?.booking_info?.tenant}</td></tr>}
                                            <tr><td>Описание: </td><td>{selectedBookingDescription?.booking_info?.description}</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='buttons-footer'>
                        <Button text='Готово' onClick={onConfirmNavigate} />
                        {
                            (
                                [Roles.ADMIN, Roles.SUPERADMIN].includes(user.role) && selectedBookingDescription?.booking_info?.status === bookingStatus.ongoing
                                ||
                                selectedBookingDescription?.booking_info?.status === bookingStatus.booked
                            ) && <Button text='Отменить бронь' color='black' onClick={onCancelBookingClick} />
                            ||
                            selectedBookingDescription?.booking_info?.status === bookingStatus.cancelled
                                && new Date(selectedBookingDescription?.booking_info?.start_time * 1000) > getCurrentMoscowTime()
                                && [Roles.ADMIN, Roles.SUPERADMIN].includes(user.role)
                                && <Button text='Вернуть бронь' color='black' onClick={onReturnBookingClick} />
                        }
                        {[Roles.ADMIN, Roles.SUPERADMIN].includes(user.role) && selectedBookingDescription?.booking_info?.status === bookingStatus.booked && <Button text='Изменить бронь' color='white' onClick={() => setLocalOpenModal('Изменить')} />}
                    </div>
                </div>

                {[Roles.ADMIN, Roles.SUPERADMIN].includes(user.role) && (localOpenModal !== 'Изменить') && (
                    <div className='my-booking-timeline-container'>
                        {/*<TimeLine />*/}
                    </div>
                )}
            </div>
        </div>
    );
}


export default ScreenBookingDescription;
