import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { fetchBookingsByRoomsThunk, fetchBookingsInOneRoomThunk } from "../../../core/store/actions/bookingManagementActions";
import { setError, setStatus } from "../../../core/store/slices/reservationSlice";
import { bookingStatus, PrivateRoutes } from "../../../core/utils/constants";
import { cancelBooking } from "../../../core/store/actions/reservationActions";
import { useKeyUp } from "../../../core/hooks/useKeyUp";
import { addBreadCrumb } from '../../../core/store/slices/navigationSlice';

import Table from "../../UI/Table/Table";
import DefaultModal from "../DefaultModal/DefaultModal";
import ColorRingLoader from "../../UI/ColorRingLoader/ColorRingLoader";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

import './BookingsByRoomRegistry.css';
import {Keys} from "../../../core/utils/constants";


function BookingsByRoomRegistry({onExitClick}) {
    const columns = ['Мероп-ие', 'Бронь', 'Дата брони', 'Время брони', 'Прод-ность', 'Статус', 'Описание']
    const indexOfId = columns.indexOf('Бронь')

    const dispatch = useDispatch();

    const { error, status } = useSelector((state) => state.reservation);
    const { date, loading, selectedRoomId, bookingsInOneRoom } = useSelector((state) => state.bookingManagement);
    const [rows, setRows] = useState([]);

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [bookingIdToDelete, setBookingIdToDelete] = useState(null);

    useEffect(() => {
        dispatch(fetchBookingsInOneRoomThunk({ date, selectedRoomId }));
    }, []);

    useEffect(() => {
        let _rows = [];

        bookingsInOneRoom?.map((element) =>
            _rows.push([
                element.event,
                element.id,
                new Date(element.date * 1000).toLocaleDateString(),
                new Date(element.start_time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                `${Math.floor(Math.abs(element.start_time - element.finish_time) * 1000 % 86400000 / 3600000)} ч ${Math.round(Math.abs(element.start_time - element.finish_time) * 1000 % 86400000 % 3600000 / 60000)} мин`,
                element.status===bookingStatus.ongoing ? <div>{element.status}<span className='blink'> ◉</span></div> : element.status,
                element.description,
                element.status === bookingStatus.booked && 'cancelButton',
            ])
        );
        setRows(_rows);
    }, [bookingsInOneRoom]);

    useKeyUp(Keys.KEY_NAME_ESC, !error && !status && !deleteConfirmationModal && onExitClick);

    const onEnterClick = () => {
        dispatch(addBreadCrumb({ name: 'Управление бронями', link: PrivateRoutes.BookingManagement }));
    }

    const onCancelBookingClick = (bookingIdToDelete) => {
        setDeleteConfirmationModal(true);
        setBookingIdToDelete(bookingIdToDelete);
    }

    const onConfirmCancelingBookingClick = async () => {
        setDeleteConfirmationModal(false);
        dispatch(cancelBooking({ selectedBookingId: bookingIdToDelete }));
    }

    const onRejectCancelingBookingClick = () => {
        setBookingIdToDelete(null);
        setDeleteConfirmationModal(false);
    }

    const onModalCompleteExit = () => {
        dispatch(setStatus(null));
        dispatch(fetchBookingsInOneRoomThunk({ date, selectedRoomId }));
        dispatch(fetchBookingsByRoomsThunk({ date }));
    }


    return (
        <div className='background'>
            {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={() => dispatch(setError(null))} />}
            {status && <DefaultModal title='Бронь отменена!' buttonText='Готово' onButtonClick={onModalCompleteExit} />}

            {deleteConfirmationModal && <ConfirmationModal title='Ты действительно хочешь отменить бронь?' onYesButtonClick={onConfirmCancelingBookingClick} onNoButtonClick={onRejectCancelingBookingClick} />}

            <div className='booking-room-registry-container'>
                <div className='booking-room-registry-title'>
                    Брони
                    <img onClick={onExitClick} className='booking-room-registry-exit' src='/img/icons/х.svg' alt='X' />
                </div>
                <div className='booking-room-registry-table-wrapper'>
                    <Table routeTo={PrivateRoutes.BookingDescription} onEnterClick={onEnterClick} columns={columns} rows={rows} nowrap={true} onCancelBookingClick={onCancelBookingClick} indexId={indexOfId} />
                </div>
            </div>
        </div>
    );
}

export default BookingsByRoomRegistry;
