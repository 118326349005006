import { api } from "../api";


export default class ReservationService {

    static async getLocations() {
        return api.post('/get-locations');
    }

    static async findRooms(location, booking_date, booking_time_start, booking_time_finish, capacity) {
        return api.post('/find-rooms', { location, booking_date, booking_time_start, booking_time_finish, capacity });
    }

    static async getUserBookings(user_id) {
        return api.post('/get-user-bookings', { user_id });
    }

    static async getBooking(booking_id) {
        return api.post('/get-booking', { booking_id });
    }

    static async getRoomInfo(room_id, location_id) {
        return api.post('/get-room-info', { rooms_id: [room_id], location_id });
    }

    static async getAllRooms(search) {
        return api.post('/get-room-info', { rooms_id: [], search })
    }

    static async getAllEvents() {
        return api.post('/get-event-types');
    }

    static async bookingCreate(room, booking_date, booking_time_start, booking_time_finish, description, event_type, tenant) {
        return api.post('/create-booking', { room, booking_date, booking_time_start, booking_time_finish, description, event_type, tenant });
    }

    static async bookingCancel(booking_id) {
        return api.post('/cancel-booking', { booking_id });
    }

    static async bookingReturn(booking_id) {
        return api.post('/back-booking', { booking_id });
    }

    static async editRoom(room_id, name, capacity, description, equipment, location_id, photo_data) {
        return api.post('/edit-room-info', { room_id, name, capacity, description, equipment, location_id, photo_data });
    }

    static async getPaginateBookings(page_num, page_size, search, order_by, order_descending) {
        return api.post('/get-booking-list', { page_num, page_size, search, order_by, order_descending })
    }

    static async getBookingsByRooms(booking_date, search) {
        return api.post('/get-booking-list-by-rooms', { booking_date, search })
    }

    static async getBookingsInOneRoom(booking_date, room_id) {
        return api.post('/get-booking-list-by-rooms', { booking_date, room_id });
    }

    static async getCurrentLocation() {
        return api.post('/get-current-location');
    }

    static async editBooking(booking_id, room, booking_date, booking_time_start, booking_time_finish, description, event_type, tenant) {
        return api.post('/edit-booking', { booking_id, room, booking_date, booking_time_start, booking_time_finish, description, event_type, tenant });
    }

    static async getEvent(event, with_bookings) {
        return api.post('/get-event', { event, with_bookings });
    }

    static async getPaginateParties(page_num, page_size, search, order_by, order_descending) {
        return api.post('/get-events', { with_bookings: false, page_num, page_size, search, order_by, order_descending })
    }

    static async getPaginateEvents(party_id, page_num, page_size, search) {
        return api.post('/get-event-list', { party_id, page_num, page_size, search })
    }

    static async createOrEditEvent(id, name, event_type, description, tenant, is_active, rooms, repeatable, repeat_type, repetition_period, event_time, event_time_finish, start_date, finish_date, avoid_weekends) {
        if (id)
            return api.post('/edit-event', { id, name, event_type, description, tenant, is_active, rooms, repeatable, repeat_type, repetition_period, event_time, event_time_finish, start_date, finish_date, avoid_weekends })
        else
            return api.post('/edit-event', { name, event_type, description, tenant, is_active, rooms, repeatable, repeat_type, repetition_period, event_time, event_time_finish, start_date, finish_date, avoid_weekends })
    }

    static async getEventConflict(event) {
        return api.post('/get-event-conflicts', { event })
    }

    static async deactivateEvent(event) {
        return api.post('/deactivate-event', { event })
    }

    static async activateEvent(event) {
        return api.post('/activate-event', { event })
    }

    static async resolveEventConflicts(event) {
        return api.post('/resolve-event-conflicts', { event })
    }

    static async fineBooking(booking_id) {
        return api.post('/set-booking-as-empty', { booking_id })
    }

}
