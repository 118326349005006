import React from 'react';
import './OfficePicker.css';
import {useSelector} from "react-redux";
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {Keys} from "../../../core/utils/constants";

function OfficePicker({selectedOfficeId, onPick, closeModal}) {
    const {locations} = useSelector((state) => state.reservation)

    useKeyUp(Keys.KEY_NAME_ESC, closeModal)

    let officesComponent = locations.map(city =>
        <div className='city-office-content' key={city.id}>
            <span className='city-content'>{city.name}</span>
            {city.sublocations.map(office =>
                <div className={'office-content ' + (selectedOfficeId===office.id ? 'office-active' : '')} key={office.id} onClick={() => onPick(office.id, office.name)}>
                    {office.name}
                </div>
            )}
        </div>
    )


    return (
        <div className='office-container'>
            <div className='office-list'>
                {officesComponent}
            </div>
        </div>
    )
}

export default OfficePicker