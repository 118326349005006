import React, {useCallback, useEffect, useState} from 'react';
import './PartyChanging.css'
import Header from "../../../components/Header/Header";
import Menu from "../../../components/UI/Menu/Menu";
import FilterButton from "../../../components/UI/FilterButton/FilterButton";
import {Modals, PrivateRoutes, RepeatType} from "../../../core/utils/constants";
import {formatTime, formatToISODateString} from "../../../core/utils/functions";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../components/UI/Button/Button";
import DayPicker from "../../../components/modals/DayPicker/DayPicker";
import {setOpenedModal} from "../../../core/store/slices/modalsSlice";
import DropDownTimePicker from "../../../components/modals/DropDownTimePicker/DropDownTimePicker";
import DescriptionSetter from "../../../components/modals/DescriptionSetter/DescriptionSetter";
import {
    clearPMStates,
    setPMDate,
    setPMDateFinish,
    setPMDescription, setPMError,
    setPMFinishTime,
    setPMHeadPerson, setPMPartyIdToReturn, setPMSelectedPartyId,
    setPMStartTime, setPMStatus,
    setPMTopic
} from "../../../core/store/slices/partiesManagementSlice";
import {createBooking, fetchEvents} from "../../../core/store/actions/reservationActions";
import Schedule from "../../../components/modals/Schedule/Schedule";
import InputField from "../../../components/UI/InputField/InputField";
import {
    fetchEventConflictsThunk,
    fetchSelectedPartyThunk,
    partyChangingThunk
} from "../../../core/store/actions/partiesManagementActions";
import {useNavigate, useParams} from "react-router-dom";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import DefaultModal from "../../../components/modals/DefaultModal/DefaultModal";
import {fetchAllRoomsThunk} from "../../../core/store/actions/roomsManagementActions";
import ColorRingLoader from "../../../components/UI/ColorRingLoader/ColorRingLoader";

function ScreenPartyChanging() {

    const { allRooms: rawRooms } = useSelector((state) => state.roomsManagement);
    const { loading, error, status, partyIdToReturn, conflicts, date, startTime, finishTime, dateFinish, topic, description, headPerson, selectedPartyId, selectedPartyDescription } = useSelector((state) => state.partiesManagement);
    const { events } = useSelector((state) => state.reservation);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { id } = useParams()
    const adding = id === 'add'

    const { openedModal } = useSelector((state) => state.modals);

    const [allRooms, setAllRooms] = useState([])
    const [selectedRooms, setSelectedRooms] = useState([])

    const [scheduleChecked, setScheduleChecked] = useState(false)
    const [openedScheduleLocalModal, setOpenedScheduleLocalModal] = useState(false);

    const [scheduleRadio, setScheduleRadio] = useState(RepeatType.EVERY_DAY.value)
    const [withHolidays, setWithHolidays] = useState(true)


    useEffect(() => {
        if (adding) {
            dispatch(fetchEvents({}))
            dispatch(fetchAllRoomsThunk({searchText: ''}))
        }
        else {
            dispatch(setPMSelectedPartyId(Number(id)))
            dispatch(fetchSelectedPartyThunk({selectedPartyId: Number(id), withBookings: false}))
        }
        return () => {
            dispatch(clearPMStates())
        }
    },[])

    useEffect(() => {
        if (partyIdToReturn) {
            dispatch(setPMPartyIdToReturn(null));
            setOpenedScheduleLocalModal(true)
        }
    },[partyIdToReturn])

    useEffect(() => {
        if (rawRooms && adding) {
            let rooms = Object.keys(rawRooms).map(key => rawRooms[key])
            rooms.sort((a, b) => {
                if (a.city === b.city) {
                    return (a.location < b.location) ? -1 : 1;
                }

                return (a.city < b.city) ? -1 : 1;
            });
            setAllRooms(rooms)
        }
    },[rawRooms])

    useEffect(() => {
        if (selectedPartyDescription && !adding) {
            dispatch(setPMTopic(selectedPartyDescription.event_type))
            dispatch(setPMHeadPerson(selectedPartyDescription.tenant))
            dispatch(setPMDescription(selectedPartyDescription.description))
            dispatch(setPMDate(new Date(selectedPartyDescription.start_date*1000)))
            dispatch(setPMStartTime(new Date(selectedPartyDescription.event_time*1000)))
            dispatch(setPMFinishTime(new Date(selectedPartyDescription.event_time_finish*1000)))
            if (selectedPartyDescription.repeatable) {
                setScheduleChecked(true)
                dispatch(setPMDateFinish(new Date(selectedPartyDescription.finish_date*1000)))
                setScheduleRadio(Object.values(RepeatType).filter(type => type.name===selectedPartyDescription.repeat_type)[0].value)
                setWithHolidays(selectedPartyDescription.avoid_weekends)
            }
            setSelectedRooms(selectedPartyDescription.rooms)
        }
    }, [selectedPartyDescription])

    const removeFromSelected = (clickedRoom) => {
        if (selectedRooms.length > 0) {
            setAllRooms((existingRooms) => [...existingRooms, clickedRoom])
            setSelectedRooms(
                selectedRooms.filter((room) => room !== clickedRoom)
            )
        }
    }

    const addToSelected = (clickedRoom) => {
        if (allRooms.length > 0) {
            setSelectedRooms((existingRooms) => [...existingRooms, clickedRoom]);
            setAllRooms(
                allRooms.filter((room) => room !== clickedRoom)
            );
        }
    }

    const closeModal = useCallback(() => {
        dispatch(setOpenedModal(''));
    }, []);

    const onTimeConfirmClick = (startTime, finishTime) => {
        dispatch(setPMStartTime(startTime));
        dispatch(setPMFinishTime(finishTime));
        dispatch(setOpenedModal(''));
    }
    const changeDate = (date) => {
        dispatch(setPMDate(date))
    }
    const changeDateFinish = (date) => {
        dispatch(setPMDateFinish(date))
    }
    const changeHeadPerson = (e) => {
        dispatch(setPMHeadPerson(e.target.value))
    }
    const changeTopic = (obj) => {
        let result = events.event_types?.filter(el => {
            return el.title === obj.value
        })
        dispatch(setPMTopic(result[0]))
    }
    const changeDescription = (e) => {
        dispatch(setPMDescription(e.target.value))
    }
    const openScheduleModal = () => {

        setOpenedScheduleLocalModal(true)
    }
    const exitScheduleClick = () => {
        setOpenedScheduleLocalModal(false);
    }
    const onConfirmPartyChanging = () => {
        // check conflicts
        if (topic===null || description===null) {
            dispatch(setPMError('Сначала заполни тему и описание!'))
            return
        }
        const name = 'name'
        dispatch(partyChangingThunk({
            selectedPartyId, name, topic: topic.id, description, headPerson, is_active: true,
            rooms: selectedRooms.map(room => room.id), scheduleChecked,
            repeatType: Object.values(RepeatType).filter(type => type.value===scheduleRadio)[0].name,
            repetitionPeriod: scheduleRadio===RepeatType.EVERY_WEEK.value ? [7, 1, 2, 3, 4, 5, 6][date.getDay()] : date.getDate(),
            startTime, finishTime, date, dateFinish, withHolidays
        }))
    }

    useEffect(() => {
        if (status?.success===true) {
            let partyId = selectedPartyId
            if (partyId===null) {
                dispatch(setPMSelectedPartyId(status.event_id))
                partyId = status.event_id
            }
            setOpenedScheduleLocalModal(true)
        }
    },[status])

    return (
        <div className='party-adding-screen'>
            <Header title='Управление мероприятиями' />
            <BreadCrumbs />
            <Menu />

            {loading && <ColorRingLoader />}

            {openedScheduleLocalModal && <Schedule onExitClick={exitScheduleClick} adding={adding}/>}

            {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={() => dispatch(setPMError(null))} />}

            {(openedModal === Modals.DescriptionSetter) &&
                <DescriptionSetter
                    topic={topic} description={description} headPerson={headPerson} events={events}
                    changeTopic={changeTopic} changeHeadPerson={changeHeadPerson} changeDescription={changeDescription} closeModal={closeModal}
                />
            }
            {(openedModal === Modals.DatePicker) && <DayPicker selectedDate={date} onPick={changeDate} closeModal={closeModal} />}
            {(openedModal === Modals.DropDownTimePicker) && <DropDownTimePicker date={date} startTime={startTime} finishTime={finishTime} onConfirmClick={onTimeConfirmClick} closeModal={closeModal} />}

            <div className='party-adding-main-wrapper'>
                <div className='party-adding-title'>
                    {!adding ? 'Мероприятие ' + selectedPartyId : 'Новое мероприятие' }
                </div>

                <div className='party-adding-content-wrapper'>
                    <section className='party-adding-chooser-panel'>
                        <div className='party-adding-subtitle'>{!adding ? 'Просмотр элемента' : 'Создание элемента'}</div>

                        <FilterButton title='Описание мероприятия' text={topic ? topic.title : 'Выбери тему мероприятия'} icon='Description' modalName={Modals.DescriptionSetter} disabled={!adding}/>
                        <FilterButton title='Дата' text={date.toLocaleDateString()} icon='Date' modalName={Modals.DatePicker} disabled={!adding}/>
                        <FilterButton title='Удобное время' text={`${formatTime(startTime)} - ${formatTime(finishTime)}`} icon='Time' modalName={Modals.DropDownTimePicker} disabled={!adding}/>
                        <label className='party-adding-schedule-checker party-adding-subtitle'>
                            <input disabled={!adding} className="checkbox" type="checkbox" checked={scheduleChecked} onChange={() => setScheduleChecked(!scheduleChecked)}/>
                            Установить расписание
                        </label>
                        {
                            scheduleChecked &&
                                <div className='party-adding-schedule-panel'>

                                    <InputField
                                        inputDate={true} title='Начало периода' height={102} placeholder='Введи текст' icon='Date' disabled={!adding}
                                        value={formatToISODateString(date)} onChange={(event) => event.target.valueAsDate && changeDate(event.target.valueAsDate)}
                                    />
                                    <InputField
                                        inputDate={true} title='Конец периода' height={102} placeholder='Введи текст' icon='Date' disabled={!adding}
                                        value={formatToISODateString(dateFinish)} onChange={(event) => event.target.valueAsDate && changeDateFinish(event.target.valueAsDate)}
                                    />
                                    <div className='party-adding-radio-panel'>
                                        <label className='party-adding-checkers'>
                                            <input disabled={!adding} className="radio" type="radio" value={RepeatType.EVERY_DAY.value} checked={scheduleRadio === RepeatType.EVERY_DAY.value} onChange={(event) => setScheduleRadio(event.target.value)} />
                                            {RepeatType.EVERY_DAY.value}
                                        </label><br />
                                        <label className='party-adding-checkers'>
                                            <input disabled={!adding} className="radio" type="radio" value={RepeatType.EVERY_WEEK.value} checked={scheduleRadio === RepeatType.EVERY_WEEK.value} onChange={(event) => setScheduleRadio(event.target.value)} />
                                            {RepeatType.EVERY_WEEK.value}
                                        </label><br />
                                        <label className='party-adding-checkers'>
                                            <input disabled={!adding} className="radio" type="radio" value={RepeatType.EVERY_MONTH.value} checked={scheduleRadio === RepeatType.EVERY_MONTH.value} onChange={(event) => setScheduleRadio(event.target.value)} />
                                            {RepeatType.EVERY_MONTH.value}
                                        </label><br />
                                    </div>
                                    <div className='party-adding-checker'>
                                        <label className='party-adding-checkers'>
                                            <input disabled={!adding} className="checkbox" type="checkbox" checked={withHolidays} onChange={() => setWithHolidays(!withHolidays)}/>
                                            Учитывать выходные
                                        </label>
                                    </div>
                                </div>
                        }
                    </section>
                    <section>
                        <div className='party-adding-rooms-wrapper'>
                            <div className='party-adding-rooms'>
                                <div className='party-adding-subtitle'>Выбранные переговорки</div>
                                <div className='party-adding-rooms-list'>
                                    {selectedRooms.map((room) =>
                                        <div key={room.id} className='party-adding-rooms-list-element'>
                                            <div className='party-adding-rooms-list-title-box'>
                                                <div className='party-adding-rooms-list-title'>{room.name}</div>
                                            </div>
                                            <div className='party-adding-rooms-list-button' onClick={() => removeFromSelected(room)}>-</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='party-adding-rooms'>
                                <div className='party-adding-subtitle'>Все переговорки</div>
                                <div className='party-adding-rooms-list'>
                                    {allRooms.map((room) =>
                                        <div key={room.id} className='party-adding-rooms-list-element'>
                                            <div className='party-adding-rooms-list-title-box pa-gray'>
                                                <div className='party-adding-rooms-list-title'>{room.name}</div>
                                            </div>
                                            <div className='party-adding-rooms-list-button' onClick={() => addToSelected(room)}>+</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='party-adding-buttons-footer'>
                            {!adding && <Button text='Посмотреть расписание' color='black' onClick={openScheduleModal}/>}
                            {adding && <Button text='Готово' onClick={onConfirmPartyChanging}/>}
                        </div>
                    </section>
                </div>

            </div>

        </div>
    );
}

export default ScreenPartyChanging;