import React from 'react';
import './DescriptionSetter.css';
import Button from "../../UI/Button/Button";
import Dropdown from 'react-dropdown';
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {Keys} from "../../../core/utils/constants";

function DescriptionSetter({closeModal, topic, description, headPerson, events, changeTopic, changeHeadPerson, changeDescription}) {
    useKeyUp(Keys.KEY_NAME_ESC, closeModal)
    useKeyUp(Keys.KEY_NAME_ENTER, closeModal)

    let titles = []
    events.event_types?.map((event) => {
        titles.push(event.title)
    })


    return (
        <div className='background'>
            <div className='description-modal'>
                <div className='description-container'>
                    <div className='input-panel'>
                        <div className='description-topic'>
                            <Dropdown onChange={changeTopic} value={topic?.title} options={titles} placeholder='Выбери тему мероприятия' />
                        </div>
                        <div className='description-head-person'>
                            <input onChange={changeHeadPerson} value={headPerson} className='effect-8' type='text' placeholder='Для кого бронь (если не для себя)' />
                                <span className='focus-border'>
                                    <i></i>
                                </span>
                        </div>
                        <div className='description-description'>
                            <textarea onChange={changeDescription} value={description} className='description-description-textarea effect-8' placeholder='Описание' />
                            <span className='focus-border'>
                                <i></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='button-footer'>
                    <Button text='Готово' onClick={closeModal}/>
                </div>
            </div>
        </div>
    )
}

export default DescriptionSetter;