import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ColorRingLoader from '../UI/ColorRingLoader/ColorRingLoader';

import { fetchPaginateBookingsThunk } from '../../core/store/actions/bookingManagementActions';
import { setError, setStatus } from '../../core/store/slices/reservationSlice';
import { bookingStatus, PrivateRoutes } from '../../core/utils/constants';
import {cancelBooking, returnBooking} from '../../core/store/actions/reservationActions';
import { addBreadCrumb } from '../../core/store/slices/navigationSlice';

import Table from '../UI/Table/Table';
import DefaultModal from '../modals/DefaultModal/DefaultModal';
import ConfirmationModal from '../modals/ConfirmationModal/ConfirmationModal';

import './BookingsRegistry.css';
import {getCurrentMoscowTime} from "../../core/utils/functions";


function BookingsRegistry({ searchText }) {
    const registryColumns = [
        {name: 'Город', rawName: 'city'},
        {name: 'Офис', rawName: 'location'},
        {name: 'Переговорка', rawName: 'room_name'},
        {name: 'Мероп-ие', rawName: 'event'},
        {name: 'Бронь', rawName: 'id'},
        {name: 'Дата брони', rawName: 'booking_date'},
        {name: 'Время брони', rawName: 'start_time'},
        {name: 'Прод-ть', rawName: 'duration'},
        {name: 'Статус', rawName: 'status'},
        {name: 'Описание', rawName: 'description'}
    ]
    const indexOfId = registryColumns.map(e => e.rawName).indexOf('id')

    const { error, status } = useSelector((state) => state.reservation);
    const { pageNum, pageSize } = useSelector((state) => state.pagination);
    const { loading, paginateBookings, pageCount } = useSelector((state) => state.bookingManagement);

    const [confirmationModal, setConfirmationModal] = useState(null);
    const [bookingId, setBookingId] = useState(null);

    const dispatch = useDispatch();

    const fetchBookings = (pageNum, pageSize, searchText, sorting) => dispatch(fetchPaginateBookingsThunk({ pageNum, pageSize, searchText, sorting }))

    function getBookingsRows() {
        let rows = []
        paginateBookings.map((element, index) =>
            rows.push([
                element.city.name,
                element.location.name,
                element.room_name,
                element.event,
                element.id,
                new Date(element.date * 1000).toLocaleDateString(),
                new Date(element.start_time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                `${Math.floor(Math.abs(element.start_time - element.finish_time) * 1000 % 86400000 / 3600000)} ч ${Math.round(Math.abs(element.start_time - element.finish_time) * 1000 % 86400000 % 3600000 / 60000)} мин`,
                element.status===bookingStatus.ongoing ? <div>{element.status}<span className='blink'> ◉</span></div> : element.status,
                element.tenant + ": " + element.description,
                element.status === bookingStatus.booked && 'cancelButton'
                ||
                element.status === bookingStatus.cancelled && new Date(element.start_time * 1000) > getCurrentMoscowTime() && 'returnButton',
            ])
        )

        return rows;
    }

    const onEnterClick = () => {
        dispatch(addBreadCrumb({ name: 'Управление бронями', link: PrivateRoutes.BookingManagement }));
    }

    const onReturnBookingClick = (bookingId) => {
        setConfirmationModal({type: 'return', text: 'Ты действительно хочешь вернуть бронь к активной?'});
        setBookingId(bookingId);
    }

    const onCancelBookingClick = (bookingIdToDelete) => {
        setConfirmationModal({type: 'cancel', text: 'Ты действительно хочешь отменить бронь?'});
        setBookingId(bookingIdToDelete);
    }

    const onConfirmBookingClick = () => {
        if (confirmationModal.type === 'cancel') {
            dispatch(cancelBooking({ selectedBookingId: bookingId }));
        }
        else if (confirmationModal.type === 'return') {
            dispatch(returnBooking({ selectedBookingId: bookingId }));
        }

        setConfirmationModal(null);

    }

    const onRejectBookingClick = () => {
        setBookingId(null);
        setConfirmationModal(null);
    }

    const onModalCompleteExit = () => {
        dispatch(setStatus(null))
        dispatch(fetchPaginateBookingsThunk({ pageNum, pageSize, searchText }))
    }

    return (
        <div>
            {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={() => dispatch(setError(null))} />}
            {status && <DefaultModal title='Статус брони изменен!' buttonText='Готово' onButtonClick={onModalCompleteExit} />}

            {confirmationModal && <ConfirmationModal title={confirmationModal.text} onYesButtonClick={onConfirmBookingClick} onNoButtonClick={onRejectBookingClick} />}

            <Table
                routeTo={PrivateRoutes.BookingDescription}
                onEnterClick={onEnterClick}
                indexId={indexOfId}
                columns={registryColumns}
                rows={getBookingsRows()}
                onCancelBookingClick={onCancelBookingClick}
                onReturnBookingClick={onReturnBookingClick}
                searchText={searchText}
                onPaginateUpdate={fetchBookings}
                sortable={true}
                infiniteUpdate={true}
                pageCount={pageCount}
            />
        </div>
    );
}


export default BookingsRegistry;
