import React, {useState} from 'react';
import './PartyManagement.css'
import Header from "../../components/Header/Header";
import Menu from "../../components/UI/Menu/Menu";
import {useNavigate} from "react-router-dom";
import {PrivateRoutes} from "../../core/utils/constants";
import Table from "../../components/UI/Table/Table";
import {useDispatch, useSelector} from "react-redux";
import {
    setPMError,
    setPMPartyIdToReturn,
    setPMSelectedPartyId,
    setPMStatus
} from "../../core/store/slices/partiesManagementSlice";
import {
    activatePartyThunk,
    deactivatePartyThunk,
    fetchPaginatePartiesThunk
} from "../../core/store/actions/partiesManagementActions";
import {addBreadCrumb} from "../../core/store/slices/navigationSlice";
import ConfirmationModal from "../../components/modals/ConfirmationModal/ConfirmationModal";
import DefaultModal from "../../components/modals/DefaultModal/DefaultModal";

function ScreenPartyManagement() {
    const {loading, error, status, paginateParties, pageCount, partyIdToReturn} = useSelector((state) => state.partiesManagement);
    const { pageNum, pageSize } = useSelector((state) => state.pagination);
    const [managementSwitch, setManagementSwitch] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const registryColumns = [
        {name: 'Мероп-ие', rawName: 'id'},
        {name: 'Тип', rawName: 'event_type'},
        {name: 'Описание', rawName: 'tenant'},
        {name: 'Даты мероприятия', rawName: 'finish_date'},
        {name: 'Время начала', rawName: 'event_time'},
        {name: 'Прод-ть', rawName: 'event_time_finish'},
        {name: 'Периодичность', rawName: 'repeatable'},
        {name: 'Статус', rawName: 'is_active'},
    ]
    const indexOfId = registryColumns.map(e => e.rawName).indexOf('id')


    const [searchText, setSearchText] = useState("")

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [partyIdToDelete, setPartyIdToDelete] = useState(null);
    const [returnConfirmationModal, setReturnConfirmationModal] = useState(false);

    const fetchParties = (pageNum, pageSize, searchText, sorting) => dispatch(fetchPaginatePartiesThunk({ pageNum, pageSize, searchText, sorting }))

    function getPartiesRows(nonRepeatableOnly) {
        let rows = []
        let parties = paginateParties
        if (nonRepeatableOnly) {
            parties = parties.filter(party => party.repeatable === false)
        }

        parties?.map(element =>
            rows.push([
                element.id,
                element.event_type.title,
                element.tenant + ": " + element.description,
                `${new Date(element.start_date * 1000).toLocaleDateString()} - ${new Date(element.finish_date * 1000).toLocaleDateString()}`,
                new Date(element.event_time * 1000).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                }),
                `${Math.floor(Math.abs(element.event_time - element.event_time_finish) * 1000 % 86400000 / 3600000)} ч ${Math.round(Math.abs(element.event_time - element.event_time_finish) * 1000 % 86400000 % 3600000 / 60000)} мин`,
                element.repeatable ? 'Повторяется' : 'Не повторяется',
                element.is_active ? 'Активна': 'Отменена',
                element.is_active ? 'cancelButton': 'returnButton',
            ])
        )

        return rows;
    }

    const onSwitchChange = (bool) => {
        setManagementSwitch(bool);
    }

    const onInputChange = (text) => {
        setSearchText(text)
    }

    const onAddClick = () => {
        dispatch(addBreadCrumb({ name: 'Управление мероприятиями', link: PrivateRoutes.PartyManagement }));
        dispatch(setPMSelectedPartyId(null))
        navigate(PrivateRoutes.PartyChanging + '/add')
    }

    const onCancelPartyClick = (partyIdToDelete) => {
        setDeleteConfirmationModal(true);
        setPartyIdToDelete(partyIdToDelete);
    }

    const onReturnPartyClick = (partyIdToReturn) => {
        setReturnConfirmationModal(true)
        dispatch(setPMPartyIdToReturn(partyIdToReturn))
    }

    const onAllCancelingBookingClick = () => {
        dispatch(deactivatePartyThunk({partyIdToDelete}))
        setPartyIdToDelete(null)
        setDeleteConfirmationModal(false)
    }

    const onReturnConfirmedPartyClick = () => {
        dispatch(activatePartyThunk({partyIdToReturn}))
        setReturnConfirmationModal(false)
    }

    const onModalCompleteExit = () => {
        if (partyIdToReturn) {
            navigate(`${PrivateRoutes.PartyChanging}/${partyIdToReturn}`)
        }
        dispatch(setPMStatus(null))
        fetchParties()
    }

    const onRejectBookingClick = () => {
        setPartyIdToDelete(null);
        setDeleteConfirmationModal(false);
        setReturnConfirmationModal(false);
        dispatch(setPMPartyIdToReturn(null));
    }

    const onEnterClick = () => {
        dispatch(addBreadCrumb({ name: 'Управление мероприятиями', link: PrivateRoutes.PartyManagement }));
    }

    return (
        <div className='party-management-screen'>
            <Header title='Управление мероприятиями' onSwitchChange={onSwitchChange} onAddClick={onAddClick} onInputChange={onInputChange} withInput />
            <Menu />

            {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={() => dispatch(setPMError(null))} />}
            {status && <DefaultModal title='Успешно!' buttonText='Готово' onButtonClick={onModalCompleteExit}/>}

            {deleteConfirmationModal &&
                <ConfirmationModal
                    title={`Внимание! \nВсе будущие брони этого мероприятия отменятся. \nТы действительно хочешь снять данное мероприятие?`}
                    onYesButtonClick={onAllCancelingBookingClick}
                    onNoButtonClick={onRejectBookingClick}
                />
            }

            {returnConfirmationModal &&
                <ConfirmationModal
                    title={`Ты действительно хочешь вернуть данное мероприятие?`}
                    onYesButtonClick={onReturnConfirmedPartyClick}
                    onNoButtonClick={onRejectBookingClick}
                />
            }

            <div className='party-management-main-wrapper'>
                {managementSwitch ?
                    <Table indexId={indexOfId} columns={registryColumns}
                           rows={getPartiesRows(true)}
                           onEnterClick={onEnterClick}
                           onCancelBookingClick={onCancelPartyClick}
                           onPaginateUpdate={fetchParties}
                           searchText={searchText}
                           routeTo={PrivateRoutes.PartyChanging}
                           sortable={true}
                           infiniteUpdate={true}
                           pageCount={pageCount}
                    />
                    :
                    <Table indexId={indexOfId} columns={registryColumns}
                           rows={getPartiesRows(false)}
                           onEnterClick={onEnterClick}
                           onCancelBookingClick={onCancelPartyClick}
                           onPaginateUpdate={fetchParties}
                           searchText={searchText}
                           routeTo={PrivateRoutes.PartyChanging}
                           onReturnBookingClick={onReturnPartyClick}
                           sortable={true}
                           infiniteUpdate={true}
                           pageCount={pageCount}
                    />
                }
            </div>
        </div>
    );
}

export default ScreenPartyManagement;