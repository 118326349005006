import React, {useState} from 'react';
import './DropDownTimePicker.css'
import Dropdown from "react-dropdown";
import Button from "../../UI/Button/Button";
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {Keys} from "../../../core/utils/constants";

const hours = [8,9,10,11,12,13,14,15,16,17,18]
const minutes = [0,15,30,45]

function DropDownTimePicker({date, startTime, finishTime, onConfirmClick, closeModal, onTimeSwitchChange}) {

    useKeyUp(Keys.KEY_NAME_ESC, closeModal)

    const [timeStartH, setTimeStartH] = useState(startTime.getHours())
    const [timeStartM, setTimeStartM] = useState(startTime.getMinutes())
    const [timeEndH, setTimeEndH] = useState(finishTime.getHours())
    const [timeEndM, setTimeEndM] = useState(finishTime.getMinutes())

    return (
        <div className='background'>
            <div className='dropdown-time-container'>
                <img onClick={closeModal} className='dropdown-time-picker-exit' src='/img/icons/х.svg' alt='X'/>
                <div className='dropdown-time-title'>
                    Изменение времени
                    {onTimeSwitchChange && <button className='time-change-view' onClick={onTimeSwitchChange}>сменить вид</button>}
                </div>
                <div className='dropdown-time-wrapper'>
                    <div className='dropdown-time-start-wrapper'>
                        <div className='time-picker-modal-title'>
                            Начало совещания
                        </div>
                        <div className='time-picker-modal-hours'>
                            <div className='time-picker-modal-hours-title'>
                                часы
                            </div>
                            <div className='dropdown-dropdown-wrapper'>
                                <Dropdown onChange={(obj) => setTimeStartH(obj.value)} value={formatTime(timeStartH)} options={hours} placeholder='ч' />
                            </div>
                        </div>
                        <div className='time-picker-modal-minutes'>
                            <div className='time-picker-modal-minutes-title'>
                                минуты
                            </div>
                            <div className='dropdown-dropdown-wrapper'>
                                <Dropdown onChange={(obj) => setTimeStartM(obj.value)} value={formatTime(timeStartM)} options={minutes} placeholder='мин' />
                            </div>
                        </div>
                    </div>

                    <div className='dropdown-time-end-wrapper'>
                        <div className='time-picker-modal-title'>
                            Конец совещания
                        </div>
                        <div className='time-picker-modal-hours'>
                            <div className='time-picker-modal-hours-title'>
                                часы
                            </div>
                            <div className='dropdown-dropdown-wrapper'>
                                <Dropdown onChange={(obj) => setTimeEndH(obj.value)} value={formatTime(timeEndH)} options={hours} placeholder='ч' />
                            </div>
                        </div>
                        <div className='time-picker-modal-minutes'>
                            <div className='time-picker-modal-minutes-title'>
                                минуты
                            </div>
                            <div className='dropdown-dropdown-wrapper'>
                                <Dropdown onChange={(obj) => setTimeEndM(obj.value)} value={formatTime(timeEndM)} options={minutes} placeholder='мин' />
                            </div>
                        </div>
                    </div>

                </div>

                <div className='dropdown-button-footer'>
                    <Button
                        text='Готово'
                        onClick={() => onConfirmClick(new Date(date.setHours(timeStartH, timeStartM)), new Date(date.setHours(timeEndH, timeEndM)))}
                    />
                </div>
            </div>

        </div>
    );
}

function formatTime(number) {
    return ('0' + number.toString()).slice(-2)
}

export default DropDownTimePicker;