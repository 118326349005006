import {createAsyncThunk} from "@reduxjs/toolkit";
import ReservationService from "../../services/ReservationService";

export const fetchSelectedPartyThunk = createAsyncThunk(
    'partyManagement/fetchSelectedPartyThunk',
    async ({selectedPartyId, withBookings}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getEvent(selectedPartyId, withBookings)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchPaginatePartiesThunk = createAsyncThunk(
    'partyManagement/fetchPaginatePartiesThunk',
    async ({pageNum, pageSize, searchText, sorting}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getPaginateParties(pageNum, pageSize, searchText, sorting?.rawName, sorting?.desc)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchPaginateEventsThunk = createAsyncThunk(
    'partyManagement/fetchPaginateEventsThunk',
    async ({selectedPartyId, pageNum, pageSize, searchText}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getPaginateEvents(selectedPartyId, pageNum, pageSize, searchText)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const partyChangingThunk = createAsyncThunk(
    'partyManagement/partyCreateThunk',
    async ({selectedPartyId, name, topic, description, headPerson, is_active, rooms, scheduleChecked, repeatType, repetitionPeriod, startTime, finishTime, date, dateFinish, withHolidays}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.createOrEditEvent(
                selectedPartyId, name, topic, description, headPerson, is_active, rooms, scheduleChecked, repeatType, repetitionPeriod,
                startTime.setSeconds(0, 0), finishTime.setSeconds(0, 0), date.setUTCHours(0, 0, 0, 0), dateFinish.setUTCHours(0, 0, 0, 0), withHolidays
            )

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchEventConflictsThunk = createAsyncThunk(
    'partyManagement/fetchEventConflictsThunk',
    async ({ selectedPartyId }, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getEventConflict(selectedPartyId)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const deactivatePartyThunk = createAsyncThunk(
    'partyManagement/deactivatePartyThunk',
    async ({ partyIdToDelete }, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.deactivateEvent(partyIdToDelete)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)


export const activatePartyThunk = createAsyncThunk(
    'partyManagement/activatePartyThunk',
    async ({ partyIdToReturn }, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.activateEvent(partyIdToReturn)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const resolveEventConflictsThunk = createAsyncThunk(
    'partyManagement/resolveEventConflictsThunk',
    async ({ partyId }, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.resolveEventConflicts(partyId)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
