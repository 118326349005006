import React from 'react';
import './RoomPicker.css'
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {Keys} from "../../../core/utils/constants";

function RoomPicker({rooms, selectedRoomId, onPick, closeModal}) {

    useKeyUp(Keys.KEY_NAME_ESC, closeModal)

    return (
        <div className='room-container'>
            <div className='room-list'>
                {rooms.map(room =>
                    <div className={'room-content ' + (selectedRoomId===room.id ? 'room-active' : '')} key={room.id} onClick={() => onPick(room.id, room.name)}>
                        {room.name}
                    </div>
                )}
            </div>
        </div>
    );
}

export default RoomPicker;