import React from 'react';
import BookingListItem from "../BookingListItem/BookingListItem";
import './BookingList.css'

function BookingList({rooms, withTimesButtons, onTimeButtonClick, onItemClick, onEditButtonClick, routeTo}) {
    return (
        <div className='booking-list'>
            {rooms?.map(element =>
                <BookingListItem
                    key={element?.room_info?.id}
                    id={element?.room_info?.id}
                    photo={element?.room_info?.photo}
                    title={element?.room_info?.name}
                    size={element?.room_info?.capacity}
                    timesButtons={withTimesButtons && element?.free_time}
                    onEditButtonClick={onEditButtonClick}
                    onClick={onItemClick}
                    onTimeButtonClick={onTimeButtonClick}
                    routeTo={routeTo}
                />)
            }
        </div>
    );
}

export default BookingList;