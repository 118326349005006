import { createSlice } from '@reduxjs/toolkit';

const paginationSlice = createSlice({
    name: 'pagination',
    initialState: {
        pageNum: 1,
        pageSize: 50,
        pageSizes: [20, 50, 100]
    },
    reducers: {
        setPageNum(state, action) {
            state.pageNum = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },

    }
});

export const { setPageNum, setPageSize } = paginationSlice.actions;

export default paginationSlice.reducer;
