import {createSlice} from "@reduxjs/toolkit";
import {getTime} from "../utils/functions";
import {TimeType} from "../../utils/constants";
import {
    activatePartyThunk,
    deactivatePartyThunk,
    fetchEventConflictsThunk,
    fetchPaginateEventsThunk,
    fetchPaginatePartiesThunk,
    fetchSelectedPartyThunk, partyChangingThunk, resolveEventConflictsThunk
} from "../actions/partiesManagementActions";
import {convertDateToMoscowTimeZone, getCurrentMoscowTime} from "../../utils/functions";

const startTime = convertDateToMoscowTimeZone(getTime(TimeType.Start))
const finishTime = convertDateToMoscowTimeZone(getTime(TimeType.Finish))
const date = convertDateToMoscowTimeZone(new Date())
const dateFinish = new Date(getCurrentMoscowTime().setMonth(getCurrentMoscowTime().getMonth() + 1))

const partiesManagementSlice = createSlice({
    name: 'partiesManagement',
    initialState: {
        status: null,
        loading: false,
        error: null,
        selectedPartyId: null,
        partyIdToReturn: null,
        selectedPartyDescription: null,
        paginateParties: [],
        selectedEventId: null,
        selectedEventDescription: null,
        paginateEvents: [],
        pageCount: 1,

        date,
        startTime,
        finishTime,
        dateFinish,
        topic: null,
        headPerson: '',
        description: '',

        conflicts: null,

        editableDate: null,
        editableStartTime: null,
        editableFinishTime: null,

        editableEventDate: convertDateToMoscowTimeZone(new Date()),
        editableEventStartTime: convertDateToMoscowTimeZone(getTime(TimeType.Start)),
        editableEventFinishTime: convertDateToMoscowTimeZone(getTime(TimeType.Finish)),
    },
    reducers: {
        setPMDate(state, action) {
            state.date = action.payload
        },
        setPMStartTime(state, action) {
            state.startTime = action.payload
        },
        setPMFinishTime(state, action) {
            state.finishTime = action.payload
        },
        setPMTopic(state, action) {
            state.topic = action.payload
        },
        setPMHeadPerson(state, action) {
            state.headPerson = action.payload
        },
        setPMDescription(state, action) {
            state.description = action.payload
        },
        setPMError(state, action) {
            state.error = action.payload
        },
        setPMStatus(state, action) {
            state.status = action.payload
        },
        setPMDateFinish(state, action) {
            state.dateFinish = action.payload
        },
        setPMSelectedPartyId(state, action) {
            state.selectedPartyId = action.payload
        },
        setPMPartyIdToReturn(state, action) {
            state.partyIdToReturn = action.payload
        },
        setPMSelectedPartyDescription(state, action) {
            state.selectedPartyDescription = action.payload
        },
        setPMEditableDate(state, action) {
            state.editableDate = action.payload
        },
        setPMEditableStartTime(state, action) {
            state.editableStartTime = action.payload
        },
        setPMEditableFinishTime(state, action) {
            state.editableFinishTime = action.payload
        },
        setPMSelectedEventId(state, action) {
            state.selectedEventId = action.payload
        },
        setPMSelectedEventDescription(state, action) {
            state.selectedEventDescription = action.payload
        },
        setPMEditableEventDate(state, action) {
            state.editableEventDate = action.payload
        },
        setPMEditableEventStartTime(state, action) {
            state.editableEventStartTime = action.payload
        },
        setPMEditableEventFinishTime(state, action) {
            state.editableEventFinishTime = action.payload
        },
        setPMConflicts(state, action) {
            state.conflicts = action.payload
        },
        clearPMStates(state, action) {
            state.status = null
            state.error = null
            state.selectedPartyId = null
            state.partyIdToReturn = null
            state.selectedPartyDescription = null
            state.paginateParties = []
            state.selectedEventId = null
            state.selectedEventDescription = null
            state.paginateEvents = []

            state.topic = null
            state.headPerson = ''
            state.description = ''
            state.date = date
            state.startTime = startTime
            state.finishTime = finishTime
            state.dateFinish = dateFinish

            state.conflicts = null

            state.editableDate = null
            state.editableStartTime = null
            state.editableFinishTime = null
            state.editableEventDate = null
            state.editableEventStartTime = null
            state.editableEventFinishTime = null
        },
    },
    extraReducers: {
        [fetchSelectedPartyThunk.pending]: (state) => {
            state.loading = true
            state.selectedPartyDescription = null
        },
        [fetchSelectedPartyThunk.fulfilled]: (state, {payload}) => {
            state.selectedPartyDescription = payload
            state.loading = false
        },
        [fetchSelectedPartyThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [fetchPaginatePartiesThunk.pending]: (state) => {
            state.loading = true
        },
        [fetchPaginatePartiesThunk.fulfilled]: (state, {payload}) => {
            state.paginateParties = payload.events
            state.pageCount = payload.page_count
            state.loading = false
        },
        [fetchPaginatePartiesThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [fetchPaginateEventsThunk.pending]: (state) => {
            state.loading = true
            state.paginateEvents = []
        },
        [fetchPaginateEventsThunk.fulfilled]: (state, {payload}) => {
            state.paginateEvents = payload.events
            state.loading = false
        },
        [fetchPaginateEventsThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [partyChangingThunk.pending]: (state) => {
            state.loading = true
            state.status = null
        },
        [partyChangingThunk.fulfilled]: (state, {payload}) => {
            state.status = payload
            state.loading = false
        },
        [partyChangingThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [fetchEventConflictsThunk.pending]: (state) => {
            state.loading = true
        },
        [fetchEventConflictsThunk.fulfilled]: (state, {payload}) => {
            state.conflicts = payload
            state.loading = false
        },
        [fetchEventConflictsThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [deactivatePartyThunk.pending]: (state) => {
            state.loading = true
            state.status = null
        },
        [deactivatePartyThunk.fulfilled]: (state, {payload}) => {
            state.status = payload
            state.loading = false
        },
        [deactivatePartyThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [activatePartyThunk.pending]: (state) => {
            state.loading = true
            state.status = null
        },
        [activatePartyThunk.fulfilled]: (state, {payload}) => {
            state.status = payload
            state.loading = false
        },
        [activatePartyThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [resolveEventConflictsThunk.pending]: (state) => {
            state.loading = true
            state.status = null
        },
        [resolveEventConflictsThunk.fulfilled]: (state, {payload}) => {
            state.status = payload
            state.loading = false
        },
        [resolveEventConflictsThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },
    }
})

export const {
    setPMError, setPMStatus, setPMDate, setPMTopic, setPMDescription, setPMHeadPerson, setPMStartTime,
    setPMFinishTime, setPMDateFinish, setPMSelectedPartyId, setPMPartyIdToReturn, setPMEditableDate, setPMConflicts,
    setPMEditableStartTime,  setPMSelectedPartyDescription, setPMEditableFinishTime, setPMSelectedEventId,
    setPMEditableEventDate, setPMSelectedEventDescription,
    setPMEditableEventStartTime, setPMEditableEventFinishTime, clearPMStates
} = partiesManagementSlice.actions

export default partiesManagementSlice.reducer