import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Modals } from "../../core/utils/constants";
import { formatTime } from "../../core/utils/functions";
import { fetchLocations, findRoomsThunk } from "../../core/store/actions/reservationActions";
import { setOpenedModal } from "../../core/store/slices/modalsSlice";
import { setDate, setError, setFinishTime, setOffice, setSelectedOfficeId, setSelectedRoomId, setStartTime } from "../../core/store/slices/reservationSlice";
import { PrivateRoutes } from "../../core/utils/constants";
import ReservationService from "../../core/services/ReservationService";

import Header from '../../components/Header/Header';
import Button from "../../components/UI/Button/Button";
import FilterButton from "../../components/UI/FilterButton/FilterButton";
import MyBookings from "../../components/MyBookings/MyBookings";
import OfficePicker from "../../components/modals/OfficePicker/OfficePicker";
import TimePicker from "../../components/modals/TimePicker/TimePicker";
import NumberPeople from "../../components/modals/NumberPeople/NumberPeople";
import DayPicker from "../../components/modals/DayPicker/DayPicker";
import FoundRooms from "../../components/FoundRooms/FoundRooms";
import Menu from "../../components/UI/Menu/Menu";
import DefaultModal from "../../components/modals/DefaultModal/DefaultModal";
import ColorRingLoader from "../../components/UI/ColorRingLoader/ColorRingLoader";
import DropDownTimePicker from "../../components/modals/DropDownTimePicker/DropDownTimePicker";

import './Reservation.css';


function ScreenReservation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { office, error, loading, selectedOfficeId, date, startTime, finishTime, numberPeople, foundRooms } = useSelector((state) => state.reservation);
    const { openedModal } = useSelector((state) => state.modals);

    const [status, setStatus] = useState('');

    useEffect(() => {
        dispatch(fetchLocations({}));

        async function fetchOffice() {
            if (!selectedOfficeId) {
                const response = await ReservationService.getCurrentLocation();

                if (response.data.location) {
                    dispatch(setSelectedOfficeId(response.data.location.id));
                    dispatch(setOffice(response.data.location.name));
                }
            }
        }

        fetchOffice();

        if (foundRooms) {
            dispatch(findRoomsThunk({ selectedOfficeId, date, startTime, finishTime, numberPeople }));
        }
    }, []);

    const closeModal = useCallback(() => {
        dispatch(setOpenedModal(''));
    }, [dispatch]);

    const onOfficeClick = (officeId, officeName) => {
        dispatch(setSelectedOfficeId(officeId));
        dispatch(setOffice(officeName));
        dispatch(setOpenedModal(''));
    }

    const findBooking = () => {
        if (selectedOfficeId) {
            dispatch(findRoomsThunk({ selectedOfficeId, date, startTime, finishTime, numberPeople }));
            dispatch(setOpenedModal(Modals.FoundRooms));
        } else {
            setStatus('Сначала выбери офис');
            dispatch(setOpenedModal(Modals.Error));
        }
    }

    const selectRoom = (roomId) => {
        dispatch(setSelectedRoomId(roomId));
        navigate(PrivateRoutes.RoomDescription);
    }

    const onTimeSwitchChange = () => {
        dispatch(setOpenedModal(Modals.DropDownTimePicker));
    }

    const onDropDownTimeSwitchChange = () => {
        dispatch(setOpenedModal(Modals.TimePicker));
    }

    const onTimeConfirmClick = (startTime, finishTime) => {
        dispatch(setStartTime(startTime));
        dispatch(setFinishTime(finishTime));
        dispatch(setOpenedModal(''));
    }

    return (
        <div className='reservation-screen'>
            <Header title='Бронирование переговорок' selectedOffice={office} modalName={(openedModal === Modals.OfficePicker) ? '' : Modals.OfficePicker} />

            <Menu />

            <div className='reservation-main-wrapper'>
                <MyBookings />

                <div className='reservation-content-wrapper'>
                    <div className='reservation-selectors'>
                        {(openedModal === Modals.OfficePicker) && (
                            <div className='reservation-office-picker-wrapper'>
                                <OfficePicker selectedOfficeId={selectedOfficeId} onPick={onOfficeClick} closeModal={closeModal} />
                            </div>
                        )}
                        {(openedModal === Modals.DatePicker) && <DayPicker selectedDate={date} onPick={(date) => dispatch(setDate(date))} closeModal={closeModal} />}
                        {(openedModal === Modals.TimePicker) && <TimePicker closeModal={closeModal} onTimeSwitchChange={onTimeSwitchChange} />}
                        {(openedModal === Modals.DropDownTimePicker) && <DropDownTimePicker date={date} startTime={startTime} finishTime={finishTime} onConfirmClick={onTimeConfirmClick} closeModal={closeModal} onTimeSwitchChange={onDropDownTimeSwitchChange} />}
                        {(openedModal === Modals.NumberPeople) && <NumberPeople closeModal={closeModal} />}
                        {(openedModal === Modals.Error) && <DefaultModal title={status} buttonText='Готово' onButtonClick={() => dispatch(setOpenedModal(''))} />}

                        {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={() => dispatch(setError(null))} />}

                        <div className='reservation-chooser-panel'>
                            <div className='chooser-panel-title'>Найти переговорку</div>

                            <FilterButton title='Дата' text={date.toLocaleDateString()} icon='Date' modalName={Modals.DatePicker} />
                            <FilterButton title='Удобное время' text={`${formatTime(startTime)} - ${formatTime(finishTime)}`} icon='Time' modalName={Modals.TimePicker} />
                            <FilterButton title='Кол-во человек' text={numberPeople} icon='People' modalName={Modals.NumberPeople} />

                            <Button text='Найти переговорку' onClick={findBooking} />
                        </div>
                    </div>

                    {loading && <ColorRingLoader />}

                    <div className='reservation-found-rooms-wrapper'>
                        {foundRooms && <FoundRooms onItemClick={selectRoom} />}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ScreenReservation;
