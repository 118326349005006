import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Timeline from 'react-calendar-timeline'
import DateHeader from 'react-calendar-timeline/lib/lib/headers/DateHeader';
import TimelineHeaders from 'react-calendar-timeline/lib/lib/headers/TimelineHeaders';
import { useDispatch } from 'react-redux';

import { setSelectedBookingId } from '../../core/store/slices/reservationSlice';
import { PrivateRoutes } from '../../core/utils/constants';
import { addBreadCrumb } from '../../core/store/slices/navigationSlice';

import './TimeLine.css';


function TimeLine({ groups, items, disabledButtons }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const onItemSelect = (itemId, e, time) => {
        dispatch(setSelectedBookingId(itemId))
        dispatch(addBreadCrumb({ name: 'Управление бронями', link: PrivateRoutes.BookingManagement }));

        navigate(`${PrivateRoutes.BookingDescription}/${itemId}`)
    }

    return (
        <div className='timeline-wrapper'>
            <Timeline
                groups={groups}
                items={items}
                lineHeight={width > 1200 ? width < 1500 ? 34 : 39 : 31}
                itemHeightRatio={0.4}
                sidebarWidth={0}
                canMove={false}
                canResize={false}
                visibleTimeStart={(new Date).setHours(8, 0, 0, 0)}
                visibleTimeEnd={(new Date).setHours(19, 0, 0, 0)}
                onItemSelect={disabledButtons ? undefined : onItemSelect}
            >
                <TimelineHeaders>
                    <DateHeader unit='hour' labelFormat='HH:mm' style={{ marginBottom: '22px' }} />
                </TimelineHeaders>
            </Timeline>
        </div>
    );
}


export default TimeLine;
