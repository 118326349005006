import { createSlice } from '@reduxjs/toolkit';

import { fetchAllRoomsThunk } from "../actions/roomsManagementActions";
import { fetchSelectedRoomThunk } from "../actions/roomsManagementActions";


const roomsManagementSlice = createSlice({
    name: 'roomsManagement',
    initialState: {
        selectedRoomId: null,
        selectedRoomDescription: null,
        allRooms: [],

        loading: false,
    },
    reducers: {
        setSelectedRoomId(state, action) {
            state.selectedRoomId = action.payload;
        },
        setSelectedRoomDescription(state, action) {
            state.selectedRoomDescription = action.payload;
        }
    },
    extraReducers: {
        [fetchAllRoomsThunk.pending]: (state) => {
            state.loading = true
            state.allRooms = null
        },
        [fetchAllRoomsThunk.fulfilled]: (state, { payload }) => {
            state.allRooms = payload
            state.loading = false
        },
        [fetchAllRoomsThunk.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        [fetchSelectedRoomThunk.pending]: (state) => {
            state.loading = true
            state.selectedRoomDescription = null
        },
        [fetchSelectedRoomThunk.fulfilled]: (state, { payload }) => {
            state.selectedRoomDescription = payload
            state.loading = false
        },
        [fetchSelectedRoomThunk.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
    }
});


export const { setSelectedRoomId, setSelectedRoomDescription } = roomsManagementSlice.actions;

export default roomsManagementSlice.reducer;
