import React from 'react';
import './InputField.css'

function InputField({title, icon, placeholder, disabled, height, value, onChange, inputDate}) {
    return (
        <div className="text-field_floating">
            <div className='text-field'>
                {inputDate ?
                    <input id="name" type="date" disabled={disabled} value={value} className="text-field__input" style={{paddingRight: '200px', paddingLeft: '50px'}} onChange={onChange} />
                    :
                    <textarea value={value} onChange={onChange} style={{height: height}} className="text-field__input"
                              disabled={disabled} id="name" placeholder={placeholder}/>
                }
                <label className="text-field__label" htmlFor="name">{title}</label>
            </div>
            <img className='text-field__icon' src={`/img/icons/${icon}.svg`} alt={icon} />
        </div>
    );
}

export default InputField;