import React, {useCallback} from 'react';
import './NumberPeople.css';
import Button from "../../UI/Button/Button";
import {useDispatch} from "react-redux";
import {setNumberPeople} from "../../../core/store/slices/reservationSlice";
import InfiniteScroller from "../../UI/InfiniteScroller/InfiniteScroller";
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {Keys} from "../../../core/utils/constants";


const peopleAmount = Array
    .from({ length: 12 }, (_, index) => index + 1)
    .filter((number) => number > 0)
    .map((number) => ({
        id: number,
        value: number,
        number: number,
    }));


function NumberPeople({closeModal}) {
    const dispatch = useDispatch()

    useKeyUp(Keys.KEY_NAME_ESC, closeModal)
    useKeyUp(Keys.KEY_NAME_ENTER, closeModal)

    const setNumberPeopleCallback = useCallback((index) => {
        dispatch(setNumberPeople(peopleAmount[index].number))
    }, [])

    return (
        <div className='background'>
            <div className='number-people-container'>
                <div className='number-people-modal'>
                    <div className='number-people-modal-chooser'/>
                    <div className='number-people-modal-title'>
                        Укажи количество человек
                    </div>
                    <div className='number-people-modal-scroller'>
                        <InfiniteScroller data={peopleAmount} onSelect={setNumberPeopleCallback} />
                    </div>
                </div>
                <div className='button-footer'>
                    <Button
                        text='Готово'
                        onClick={closeModal}
                    />
                </div>
            </div>
        </div>
    )
}

export default NumberPeople;
