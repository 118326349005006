import React from 'react';
import './Booking.css'

function Booking({id, photo, title, subtitle, onClick}) {
    return (
        <div className='booking' onClick={onClick ? () => onClick(id) : undefined}>
            {photo ? <img className='room-pic' src={photo.base64} alt="Room Photo" /> : <img className='room-pic' src='/img/rooms/default_room.png' alt={`${photo}`} />}
            <div className='booking-description'>
                <div className='booking-title'>{title}</div>
                <div className='booking-text'>{subtitle}</div>
            </div>
        </div>
    );
}

export default Booking;