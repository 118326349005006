import React, {memo, useCallback, useState} from 'react';
import './TimePicker.css';
import Button from "../../UI/Button/Button";
import {hours, Keys, minutes, TimeType} from "../../../core/utils/constants";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroller from "../../UI/InfiniteScroller/InfiniteScroller";
import {setFinishTime, setStartTime} from "../../../core/store/slices/reservationSlice";
import {formatTime} from "../../../core/utils/functions";
import {useKeyUp} from "../../../core/hooks/useKeyUp";

function TimePicker({closeModal, onTimeSwitchChange}) {
    const {startTime, finishTime} = useSelector((state) => state.reservation)
    const dispatch = useDispatch()

    const [timeType, setTimeType] = useState(TimeType.Start)

    useKeyUp(Keys.KEY_NAME_ESC, closeModal)
    useKeyUp(Keys.KEY_NAME_ENTER, closeModal)

    const setStartHour = useCallback((index) => {
        dispatch(setStartTime(new Date(startTime.setHours(hours[index].number))))
    }, [])

    const setFinishHour = useCallback((index) => {
        dispatch(setFinishTime(new Date(finishTime.setHours(hours[index].number))))
    }, [])

    const setStartMinute = useCallback((index) => {
        dispatch(setStartTime(new Date(startTime.setMinutes(minutes[index].number))))
    }, [])

    const setFinishMinute = useCallback((index) => {
        dispatch(setFinishTime(new Date(finishTime.setMinutes(minutes[index].number))))
    }, [])

    return (
        <div className='background'>
            <div className='time-container'>
                <div className='time-picker'>
                    <div className='time-picker-title'>
                        Выбери время
                        {onTimeSwitchChange && <button className='time-change-view' onClick={onTimeSwitchChange}>сменить вид</button>}
                    </div>
                    <div className='time-picker-buttons-panel'>
                        <div
                            className={'time-picker-button-start ' + (timeType === TimeType.Start ? 'active-button' : '')}
                            onClick={() => setTimeType(TimeType.Start)}
                        >
                            {formatTime(startTime)}
                        </div>
                        {' - '}
                        <div
                            className={'time-picker-button-finish ' + (timeType === TimeType.Finish ? 'active-button' : '')}
                            onClick={() => setTimeType(TimeType.Finish)}
                        >
                            {formatTime(finishTime)}
                        </div>
                    </div>
                    <div className='time-picker-modal'>
                        <div className='time-picker-modal-chooser'></div>
                        <div className='time-picker-modal-title'>
                            {timeType === TimeType.Start ? 'Начало совещания' : 'Конец совещания'}
                        </div>
                        <div className='time-picker-modal-hours'>
                            <div className='time-picker-modal-hours-title'>
                                часы
                            </div>
                            <div className='time-picker-modal-hours-scroller'>
                                {timeType === TimeType.Start &&
                                    <InfiniteScroller
                                        data={hours}
                                        onSelect={setStartHour}
                                        defaultIndex={startTime.getHours() - 8}
                                    />
                                }
                                {timeType === TimeType.Finish &&
                                    <InfiniteScroller
                                        data={hours}
                                        onSelect={setFinishHour}
                                        defaultIndex={finishTime.getHours() - 8}
                                    />
                                }
                            </div>
                        </div>
                        <div className='time-picker-modal-minutes'>
                            <div className='time-picker-modal-minutes-title'>
                                минуты
                            </div>
                            <div className='time-picker-modal-minutes-scroller'>
                                {timeType === TimeType.Start &&
                                    <InfiniteScroller
                                        data={minutes}
                                        onSelect={setStartMinute}
                                        defaultIndex={minutes.filter(el => el.number===startTime.getMinutes())[0].id}
                                    />
                                }
                                {timeType === TimeType.Finish &&
                                    <InfiniteScroller
                                        data={minutes}
                                        onSelect={setFinishMinute}
                                        defaultIndex={minutes.filter(el => el.number===finishTime.getMinutes())[0].id}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='button-footer'>
                    <Button
                        text='Готово'
                        onClick={closeModal}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(TimePicker)
