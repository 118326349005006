export const Roles = {
    VIEWER: 'viewer',
    USER: 'user',
    ADMIN: 'admin',
    SUPERADMIN: 'superadmin',
}

export const Modals = {
    OfficePicker: 'OfficePicker',
    DatePicker: 'DatePicker',
    TimePicker: 'TimePicker',
    DropDownTimePicker: 'DropDownTimePicker',
    NumberPeople: 'NumberPeople',
    DescriptionSetter: 'DescriptionSetter',
    FoundRooms: 'FoundRooms',
    RoomDescription: 'RoomDescription',
    Rooms: 'Rooms',

    Confirmed: 'Confirmed',
    Error: 'Error',
}

export const HourLimits = {
    UP: 8,
    DOWN: 18
}

export const TimeType = {
    Start: 'Start',
    Finish: 'Finish'
}

export const minutes = [
    {id:0, value:'00', number: 0},
    {id:1, value:'15', number: 15},
    {id:2, value:'30', number: 30},
    {id:3, value:'45', number: 45},
]

export const hours = [
    {id:8, value:'08', number: 8},{id:9, value:'09', number: 9},{id:10, value:'10', number: 10},
    {id:11, value:'11', number: 11},{id:12, value:'12', number: 12},{id:13, value:'13', number: 13},
    {id:14, value:'14', number: 14},{id:15, value:'15', number: 15},{id:16, value:'16', number: 16},
    {id:17, value:'17', number: 17},{id:18, value:'18', number: 18},
]

export const Keys = {
    KEY_NAME_ESC: 'Escape',
    KEY_NAME_ENTER: 'Enter',
}

export const bookingStatus = {
    booked: 'Активна',
    cancelled: 'Отменена',
    ended: 'Завершена',
    ongoing: 'В процессе',
}

export const PublicRoutes = {
    SignIn: '/sign-in',
    ViewerSignIn: '/viewer-sign-in',
}

export const PrivateRoutes = {
    Reservation: '/reservation',
    BookingDescription: '/booking-description',
    RoomDescription: '/room-description',
    BookingManagement: '/booking-management',
    MeetingRoomsManagement: '/meeting-rooms-management',
    PartyManagement: '/party-management',
    PartyChanging: '/party-changing',
    BookingRoomsBrowse: '/booking-rooms-browse',

    AdminRoomDescription: '/admin-room-description',
    RoomEditing: '/room-editing'
}

export const RepeatType = {
    NO_REPEAT: {value: 'Не повторять', name: 'Не повторять'},
    EVERY_DAY: {value: 'Ежедневно', name: 'Каждый день'},
    EVERY_WEEK: {value: 'Еженедельно', name: 'Каждую неделю'},
    EVERY_MONTH: {value: 'Ежемесячно', name: 'Каждый месяц'}
}
