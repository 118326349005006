import {createSlice} from '@reduxjs/toolkit'
import {
    browserTgWidgetSignInThunk,
    checkAuthWithHeaderThunk,
    checkAuthWithCookieThunk,
    tgWebAppSignInThunk,
    viewerSignInThunk,
    signOutThunk
} from "../actions/authActions";
import {
    getAccessTokenOrNullFromStorage,
    getRefreshTokenOrNullFromStorage,
    putAccessTokenToStorage
} from "../utils/functions";

const accessToken = getAccessTokenOrNullFromStorage()
const refreshToken = getRefreshTokenOrNullFromStorage()

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuth: false,
        user: null,
        accessToken,
        refreshToken,
        loading: true,
        error: null
    },
    reducers: {},
    extraReducers: {
        [tgWebAppSignInThunk.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [tgWebAppSignInThunk.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.isAuth = true
            state.user = payload.user
            state.accessToken = payload.access_token
            state.refreshToken = payload.refresh_token
        },
        [tgWebAppSignInThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [browserTgWidgetSignInThunk.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [browserTgWidgetSignInThunk.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.isAuth = true
            state.user = payload.user
            state.accessToken = payload.access_token
        },
        [browserTgWidgetSignInThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [viewerSignInThunk.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [viewerSignInThunk.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.user = payload.user
            state.accessToken = payload.access_token
            state.refreshToken = payload.refresh_token
        },
        [viewerSignInThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [checkAuthWithHeaderThunk.pending]: (state) => {
            state.loading = true
            state.isAuth = false
            state.user = null
            state.error = null
        },
        [checkAuthWithHeaderThunk.fulfilled]: (state, {payload}) => {
            state.isAuth = true
            state.user = payload.user
            state.accessToken = payload.access_token
            state.refreshToken = payload.refresh_token
            state.loading = false
        },
        [checkAuthWithHeaderThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [checkAuthWithCookieThunk.pending]: (state) => {
            state.loading = true
            state.user = null
            state.isAuth = false
            state.error = null
        },
        [checkAuthWithCookieThunk.fulfilled]: (state, {payload}) => {
            state.isAuth = true
            state.user = payload.user
            putAccessTokenToStorage(payload.access_token)
            state.accessToken = payload.access_token
            state.loading = false
        },
        [checkAuthWithCookieThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [signOutThunk.pending]: (state) => {
            state.loading = true
            state.user = null
            state.error = null
        },
        [signOutThunk.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.user = null
        },
        [signOutThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },
    },
})

export default authSlice.reducer