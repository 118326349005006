import {createAsyncThunk} from "@reduxjs/toolkit";
import ReservationService from "../../services/ReservationService";

export const fetchAllRoomsThunk = createAsyncThunk(
    'roomsManagement/fetchAllRoomsThunk',
    async ({searchText}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getAllRooms(searchText)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchSelectedRoomThunk = createAsyncThunk(
    'roomsManagement/fetchSelectedRoom',
    async ({selectedRoomId}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getRoomInfo(selectedRoomId)

            return data[selectedRoomId]
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
