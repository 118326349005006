import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {setSelectedRoomId} from '../../../core/store/slices/roomsManagementSlice';
import ReservationService from '../../../core/services/ReservationService';
import { PrivateRoutes } from '../../../core/utils/constants';
import {setOpenedModal} from "../../../core/store/slices/modalsSlice";
import {Modals} from "../../../core/utils/constants";

import DefaultModal from "../../../components/modals/DefaultModal/DefaultModal";
import Header from '../../../components/Header/Header';
import Menu from '../../../components/UI/Menu/Menu';
import Button from '../../../components/UI/Button/Button';
import InputField from '../../../components/UI/InputField/InputField';
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';

import './RoomEditing.css';
import {fetchSelectedRoomThunk} from "../../../core/store/actions/roomsManagementActions";


function ScreenRoomEditing() {
    const { selectedRoomId, selectedRoomDescription } = useSelector((state) => state.roomsManagement);
    const { openedModal } = useSelector((state) => state.modals)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams()

    const [name, setName] = useState(selectedRoomDescription?.name);
    const [capacity, setCapacity] = useState(selectedRoomDescription?.capacity);
    const [description, setDescription] = useState(selectedRoomDescription?.description);
    const [equipment, setEquipment] = useState(selectedRoomDescription?.equipment);
    const [file, setFile] = useState(selectedRoomDescription?.photo.base64);
    const [status, setStatus] = useState('')

    useEffect(() => {
        dispatch(setSelectedRoomId(Number(id)))
        dispatch(fetchSelectedRoomThunk({selectedRoomId: Number(id)}))
    }, []);

    useEffect(() => {
        setName(selectedRoomDescription?.name);
        setCapacity(selectedRoomDescription?.capacity);
        setDescription(selectedRoomDescription?.description);
        setEquipment(selectedRoomDescription?.equipment);
        setFile(selectedRoomDescription?.photo.base64);
    }, [selectedRoomDescription]);

    const handleChange = async (event) => {
        const selectedFile = event.target.files[0];
        const base64 = await convertToBase64(selectedFile);

        setFile(base64);
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const closeModal = () => {
        dispatch(setOpenedModal(''))
    }

    const onConfirmClick = async () => {
        try {
            await ReservationService.editRoom(selectedRoomId, name, isNaN(Number(capacity)) ? 0 : capacity, description, equipment, selectedRoomDescription?.location, file);
            navigate(PrivateRoutes.MeetingRoomsManagement);
        } catch (error) {
            setStatus(error.response.data.message)
            dispatch(setOpenedModal(Modals.Error))
        }
    }

    return (
        <div className='room-editing-screen'>
            {openedModal===Modals.Error && <DefaultModal title={status} buttonText='Готово' onButtonClick={closeModal} />}

            <Header title='Управление переговорками' />
            <BreadCrumbs />
            <Menu />

            <div className='room-editing-container'>
                <div className='room-editing-title'>
                    {selectedRoomDescription?.name}
                </div>

                <div className='room-editing-wrapper'>
                    <div className='room-editing-props'>
                        <div className='room-editing-subtitle'>Изменение элемента</div>

                        <InputField title='Наименование в УПП' value={selectedRoomDescription?.upp_name} disabled={true} placeholder='Введи текст' icon='1С' />
                        <InputField title='Наименование' value={name} onChange={(event) => setName(event.target.value)} placeholder='Введи текст' icon='name' />
                        <InputField title='Вместимость до' value={capacity} onChange={(event) => setCapacity(event.target.value)} placeholder='Введи число' icon='People' />
                        <InputField title='Описание' height={110} value={description} onChange={(event) => setDescription(event.target.value)} placeholder='Введи текст' icon='descript' />
                        <InputField title='Оснащение' height={110} value={equipment} onChange={(event) => setEquipment(event.target.value)} placeholder='Введи текст' icon='tech' />
                    </div>
                    <div className='room-editing-photo'>
                        <div className='room-editing-subtitle'>Фотография</div>

                        <img className='room-editing-image' src={file} alt='Room' />
                        <input type='file' onChange={handleChange} />

                        <div className='room-editing-button-footer'>
                            <Button text='Готово' onClick={onConfirmClick} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}


export default ScreenRoomEditing;
